import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import { UserAccounts } from '../util/Api.tsx'
import { Alert, Dialog, FormControlLabel, Paper, TextField, useTheme } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

interface UserEditProps {
  open: boolean
  onClose: () => void
  users: UserAccounts[]
  edit: boolean
  user?: UserAccounts | null
  onSave: (email: string, role: number, existingEntry?: UserAccounts) => void
  loading: boolean
}

const UserEdit = ({
  open = false,
  onClose = () => {},
  edit = false,
  user,
  onSave = () => {},
  loading,
}: UserEditProps) => {
  const theme = useTheme()
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(5)
  const [error, setError] = useState('')

  const handleClose = () => {
    setError('')
    onClose()
  }

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleChangeRole = (role: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setRole(role)
  }

  const onClickEdit = () => {
    if (edit && user) {
      onSave(email, role, user)
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(email)) {
        setError('Invalid email address')
        return
      } else {
        setError('')
      }
      onSave(email, role)
    }
  }

  useEffect(() => {
    if (user) {
      setEmail(user.email)
      setRole(user.role)
    } else {
      setEmail('')
      setRole(5)
    }
  }, [user, open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <Paper>
        <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
          <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
            <Grid container padding={2} justifyContent="space-between" alignItems="center" wrap="nowrap" minWidth={400}>
              <Grid item>
                <Typography variant="h5" noWrap>
                  {edit ? 'Edit' : 'Add'} User
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container direction="column" alignItems="stretch" justifyContent="center" padding={2} spacing={3}>
              {error && (
                <Grid item>
                  <Alert severity="warning" variant="outlined" sx={{ mb: 2 }}>
                    {error}
                  </Alert>
                </Grid>
              )}
              <Grid item>
                <Typography>Email</Typography>
                <TextField
                  disabled={edit}
                  name="Email"
                  value={email}
                  sx={{ width: '280px' }}
                  onChange={handleChangeEmail}
                />
              </Grid>

              <Grid item>
                <Grid container direction="column" alignItems="stretch" justifyContent="center">
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox checked={role === 0} onChange={handleChangeRole(0)} />}
                      label="Admin"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox checked={role === 3} onChange={(e) => setRole(e.target.checked ? 3 : 5)} />}
                      label="Blockman View-Only"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox checked={role === 5} onChange={(e) => setRole(e.target.checked ? 5 : 5)} />}
                      label="View-Only"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Button variant="contained" onClick={onClickEdit} disabled={!email || loading}>
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>
    </Dialog>
  )
}

export default UserEdit
