import React, { useState, useEffect, useCallback } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Alert from '@mui/material/Alert'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import AddIcon from '@mui/icons-material/Add'

import handleError from '../util/Error.tsx'
import useStore from '../util/Store.tsx'
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import {
  addWeightedPhrases,
  deleteWeightedPhrases,
  editWeightedPhrases,
  getSignatures,
  getWeightedPhrases,
  Signature,
  WeightedPhrase,
} from '../util/Api.tsx'
import SearchBar from '../components/SearchBar.tsx'
import PhrasesTable from '../components/PhrasesTable.tsx'
import PhraseDialog from '../components/PhraseDialog.tsx'
import ConfirmDialog from '../components/ConfirmDialog.tsx'

interface SignatureCounts {
  id: string
  count: number
  banned: boolean
  name: string
}

const WeightedPhrases = () => {
  const isMounted = useIsMounted()
  const [user] = useStore('user')
  const [darkMode] = useStore('darkMode')
  const [shownPhrases, setShownPhrases] = useState<WeightedPhrase[]>([])
  const [selectedWeightedCategory, setSelectedWeightedCategory] = useState<'banned' | 'weighted'>('weighted')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [weightedPhrases, setWeightedPhrases] = useState<WeightedPhrase[]>([])
  const [signatureCount, setSignatureCount] = useState<SignatureCounts[]>([])
  const [selected, setSelected] = useState<string | null>(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [orderBy, setOrderBy] = useState<keyof SignatureCounts>('count')
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const [shownCounts, setShownCounts] = useState<SignatureCounts[]>([])
  const [search, setSearch] = useState('')
  const [signatures, setSignatures] = useState<Signature[]>([])
  const [deleteActive, setDeleteActive] = useState<WeightedPhrase | null>(null)
  const [addOpen, setAddOpen] = useState(false)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [searchPhrase, setSearchPhrase] = useState('')
  const [oldPhrase, setOldPhrase] = useState<WeightedPhrase | null>(null)
  const [state, setState] = useState('')

  const handleAddOpen = () => {
    setOldPhrase(null)
    setState('Add')
    setAddOpen(true)
  }

  const handleAddPhrase = async (id: string | null, phrase: string[], weight: string, banned: boolean) => {
    try {
      setLoading(true)
      setError('')
      if (!isMounted()) return
      if (!id) {
        setError('No signature selected')
        return
      }
      if (state === 'Add') {
        await addWeightedPhrases(phrase, id, weight, banned)
        setSelected(id)
        setSelectedWeightedCategory(banned ? 'banned' : 'weighted')
      } else if (state === 'Edit') {
        await editWeightedPhrases(oldPhrase, phrase, id, weight, banned)
      }

      if (!isMounted()) return
      await populateWeightedPhrases()
      setAddOpen(false)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      setDeleteConfirmOpen(false)
      setLoading(false)
    }
  }

  const handleAddClose = () => {
    setAddOpen(false)
  }

  const handleDeleteConfirmOpen = (id: WeightedPhrase) => {
    if (!id) {
      setError('No entry supplied')
    }
    setDeleteActive(id)
    setDeleteConfirmOpen(true)
  }

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false)
  }

  const handleEditOpen = async (old: WeightedPhrase) => {
    if (!old) {
      setError('No entry supplied')
      return
    }
    setOldPhrase(old)
    setState('Edit')
    setAddOpen(true)
  }

  const handleDeletePhrase = async () => {
    try {
      setLoading(true)
      if (!isMounted()) return
      if (!deleteActive) {
        setError('No phrase selected')
        return
      }
      await deleteWeightedPhrases(deleteActive)
      if (!isMounted()) return
      await populateWeightedPhrases()
      if (shownCounts.some((sig) => sig.id === deleteActive.signature && sig.count === 1)) {
        setSelected(null)
      }
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      setDeleteConfirmOpen(false)
      setLoading(false)
    }
  }

  const handleChangeSelected = async (sig: string) => {
    setSelected(sig)
    if (selectedWeightedCategory === 'banned') {
      setShownPhrases(weightedPhrases.filter((phrase) => phrase.signature === sig && phrase.banned))
    } else {
      setShownPhrases(weightedPhrases.filter((phrase) => phrase.signature === sig && !phrase.banned))
    }
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if (e.target.value !== '') {
      setShownCounts(signatureCount.filter((count) => count.id.includes(e.target.value)))
      if (selectedWeightedCategory === 'banned') {
        setShownCounts(signatureCount.filter((count) => count.id.includes(e.target.value) && count.banned))
      } else {
        setShownCounts(signatureCount.filter((count) => count.id.includes(e.target.value) && !count.banned))
      }
    } else {
      if (selectedWeightedCategory === 'banned') {
        setShownCounts(signatureCount.filter((count) => count.banned))
      } else {
        setShownCounts(signatureCount.filter((count) => !count.banned))
      }
    }
  }

  const handleChangeSearchPhrase = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.target.value.toLowerCase())
    if (e.target.value !== '') {
      if (selectedWeightedCategory === 'banned') {
        setShownPhrases(weightedPhrases.filter((phrase) => phrase.signature === selected && phrase.banned))
      } else {
        setShownPhrases(weightedPhrases.filter((phrase) => phrase.signature === selected && !phrase.banned))
      }
    }
    if (selectedWeightedCategory === 'banned') {
      setShownPhrases(
        weightedPhrases.filter(
          (phrase) =>
            phrase.signature === selected &&
            phrase.phrase.some((p) => p.toLowerCase().includes(e.target.value.toLowerCase())) &&
            phrase.banned
        )
      )
    } else {
      setShownPhrases(
        weightedPhrases.filter(
          (phrase) =>
            phrase.signature === selected &&
            phrase.phrase.some((p) => p.toLowerCase().includes(e.target.value.toLowerCase())) &&
            !phrase.banned
        )
      )
    }
  }

  const getCounts = useCallback(() => {
    const sorted = shownCounts.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentPage: (SignatureCounts | null)[] = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentPage.length
    if (currentPage.length === rowsPerPage) return currentPage
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentPage.push(null)
    }
    return currentPage
  }, [shownCounts, orderBy, page, rowsPerPage, order])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const createSortHandler = (id: keyof SignatureCounts) => (e: React.MouseEvent) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  const handleChangeCategory = (e: React.MouseEvent<HTMLElement>, newValue: 'weighted' | 'banned') => {
    setSelectedWeightedCategory(newValue)
    if (newValue === 'weighted') {
      setShownCounts(signatureCount.filter((weighted) => !weighted.banned))
      setPage(0)
      setSelected(null)
      return
    }
    setShownCounts(signatureCount.filter((weighted) => weighted.banned))
    setPage(0)
    setSelected(null)
  }

  const populateWeightedPhrases = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getWeightedPhrases()
      if (!isMounted()) return
      setWeightedPhrases(data.phrases)

      const signatureData = await getSignatures()
      setSignatures(signatureData.signatures)

      const signatures: SignatureCounts[] = []
      data.phrases.forEach((phrase) => {
        const exists = signatures.some((sig) => sig.id === phrase.signature && sig.banned === phrase.banned)
        if (exists) {
          signatures.forEach((sig) => {
            if (sig.id === phrase.signature && sig.banned === phrase.banned) {
              sig.count += 1
            }
          })
        } else {
          signatures.push({
            id: phrase.signature,
            count: 1,
            name: signatureData.signatures.find((s) => s.id === phrase.signature)?.name || phrase.signature,
            banned: phrase.banned,
          })
        }
      })
      setSignatureCount(signatures)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      setLoading(false)
    }
  }, [isMounted])

  const populateShownPhrases = useCallback(async () => {
    if (selectedWeightedCategory === 'banned') {
      setShownPhrases(weightedPhrases.filter((phrase) => phrase.signature === selected && phrase.banned))
    } else {
      setShownPhrases(weightedPhrases.filter((phrase) => phrase.signature === selected && !phrase.banned))
    }
  }, [selected, selectedWeightedCategory, weightedPhrases])

  useEffect(() => {
    populateWeightedPhrases()
  }, [populateWeightedPhrases])

  useEffect(() => {
    if (selectedWeightedCategory === 'banned') {
      setShownCounts(signatureCount.filter((sig) => sig.banned))
    } else {
      setShownCounts(signatureCount.filter((sig) => !sig.banned))
    }
  }, [selectedWeightedCategory, signatureCount])

  useEffect(() => {
    populateShownPhrases()
  }, [populateShownPhrases, weightedPhrases])

  return (
    <Grid container justifyContent="center" sx={{ mt: 3 }} padding={3}>
      {user?.role === 0 && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item>
              <Typography variant="h3">Weighted Phrases</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ mt: 1 }}>
            {error && (
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>

          <Grid container justifyContent="start" spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container spacing={1} alignItems="center" sx={{ mb: 2 }}>
                <Grid item>
                  <ToggleButtonGroup
                    value={selectedWeightedCategory}
                    exclusive
                    onChange={handleChangeCategory}
                    aria-label="type banned"
                  >
                    <ToggleButton value="weighted" aria-label="weighted">
                      Weighted
                    </ToggleButton>
                    <ToggleButton value="banned" aria-label="banned">
                      Banned
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item>
                  <SearchBar value={search} dark={!darkMode} button onChange={handleChangeSearch} />
                </Grid>
                <Grid item>
                  <Fab sx={{ mx: 2 }} color="primary" size="small" disabled={loading} onClick={handleAddOpen}>
                    <AddIcon />
                  </Fab>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Paper variant="outlined">
                  <TableContainer>
                    <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'id'}
                            direction={orderBy === 'id' ? order : 'asc'}
                            onClick={createSortHandler('id')}
                          >
                            Signature
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                          <TableSortLabel
                            active={orderBy === 'count'}
                            direction={orderBy === 'count' ? order : 'asc'}
                            onClick={createSortHandler('count')}
                          >
                            Number of Phrases associated
                          </TableSortLabel>
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {getCounts().map((sig, index) => (
                          <TableRow
                            hover
                            key={`phrases-table-item-${index}`}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              cursor: 'pointer',
                              height: 47,
                              backgroundColor:
                                sig?.id === selected && !darkMode
                                  ? '#ededed'
                                  : sig?.id === selected && darkMode
                                  ? '#4b4b4b'
                                  : null,
                            }}
                            onClick={sig ? () => handleChangeSelected(sig.id) : () => {}}
                          >
                            <TableCell>{sig?.name}</TableCell>

                            <TableCell align="right">{sig?.count || ''}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[10, 15, 25, 50]}
                    component="div"
                    count={
                      selectedWeightedCategory === 'banned'
                        ? signatureCount.filter((sig) => sig.banned).length
                        : signatureCount.filter((sig) => !sig.banned).length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Grid>
            </Grid>
            {selected && (
              <Grid item xs={12} sm={6} md={6} lg={6} paddingLeft={1}>
                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                  <Grid item mt={1}>
                    <SearchBar value={searchPhrase} dark={!darkMode} button onChange={handleChangeSearchPhrase} />
                  </Grid>
                  <Grid item xs={12}>
                    <PhrasesTable
                      shownPhrases={shownPhrases}
                      loading={loading}
                      dark={darkMode}
                      onDelete={handleDeleteConfirmOpen}
                      onEdit={handleEditOpen}
                      category={selectedWeightedCategory}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <PhraseDialog
            selected={signatures.find((sig) => sig.id === selected) || null}
            signatures={signatures}
            onSave={handleAddPhrase}
            dark={darkMode}
            open={addOpen}
            onClose={handleAddClose}
            error={error}
            weightedPhrase={oldPhrase}
            status={state}
          />
          <ConfirmDialog
            title="Weighted Phrase Delete Confirmation"
            text={`Are you sure you would to delete entry: ${deleteActive?.phrase} from ${deleteActive?.signature}`}
            open={deleteConfirmOpen}
            onConfirm={handleDeletePhrase}
            onClose={handleDeleteConfirmClose}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default WeightedPhrases
