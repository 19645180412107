import React, { useState, useEffect, useCallback } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Alert from '@mui/material/Alert'
import AddIcon from '@mui/icons-material/Add'
import useStore from '../util/Store.tsx'
import handleError from '../util/Error.tsx'
import GenericListsTable from '../components/genericLists/GenericListsTable.tsx'
import {
  addGenericList,
  addGenericListEntries,
  deleteGenericList,
  deleteGenericListEntries,
  deleteMassListEntries,
  editGenericList,
  GenericList,
  GenericListEntry,
  getGenericLists,
  getGenericListsEntries,
} from '../util/Api.tsx'
import SearchBar from '../components/SearchBar.tsx'
import DeleteConfirmDialog from '../components/DeleteConfirmDialog.tsx'
import GenericListsEntryTable from '../components/genericLists/GenericListsEntryTable.tsx'
import AddEditGenericList from '../components/genericLists/AddEditGenericList.tsx'
import AddEditGenericListEntry from '../components/genericLists/AddEditGenericListsEntry.tsx'
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material'

const GenericLists = () => {
  const isMounted = useIsMounted()
  const [user] = useStore('user')
  const [darkMode] = useStore('darkMode')
  const [genericLists, setGenericLists] = useState<GenericList[]>([])
  const [selectedGenericList, setSelectedGenericList] = useState<GenericList | null>(null)
  const [genericListEntries, setGenericListEntries] = useState<GenericListEntry[]>([])
  const [shownGenericListEntries, setShownGenericListEntries] = useState<GenericListEntry[]>([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [listCreateEditOpen, setListCreateEditOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteName, setDeleteName] = useState('')
  const [entryCreateOpen, setEntryCreateOpen] = useState(false)
  const [error, setError] = useState('')
  const [activeRow, setActiveRow] = useState<GenericList | null>(null)
  const [activeEntry, setActiveEntry] = useState<GenericListEntry | null>(null)
  const [type, setType] = useState('add')
  const [deleteOpenEntry, setDeleteOpenEntry] = useState(false)
  const [deleteNameEntry, setDeleteNameEntry] = useState('')
  const [listCategory, setListCategory] = useState('enabled')
  const [shownGenericList, setShownGenericList] = useState<GenericList[]>([])
  const [showMassDelete, setShowMassDelete] = useState(false)

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setError('')
    setActiveRow(null)
    setDeleteName('')
  }

  const handleOpenDeleteList = (list: GenericList) => {
    setActiveRow(list)
    setDeleteOpen(true)
    setDeleteName(`"${list.id}"`)
  }

  const handleOpenDeleteEntry = (entry: GenericListEntry) => {
    setActiveEntry(entry)
    setDeleteOpenEntry(true)
    setDeleteNameEntry(`"${entry.entry}"`)
  }

  const handleDeleteCloseEntry = () => {
    setDeleteOpenEntry(false)
    setError('')
    setActiveEntry(null)
    setDeleteName('')
  }

  const handleOpenMassDelete = () => {
    setShowMassDelete(true)
    setDeleteName(`"all entries relating to ${selectedGenericList?.id}"`)
  }

  const handleCloseMassDelete = () => {
    setShowMassDelete(false)
    setDeleteName('')
  }

  const handleClickEdit = (list?: GenericList) => {
    if (!list) return
    setError('')
    setType('edit')
    setActiveRow(list)
    setListCreateEditOpen(true)
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if (e.target.value === '') {
      setShownGenericListEntries(genericListEntries.filter((entry) => entry.list_id === selectedGenericList?.id))
    }
  }

  const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    setShownGenericListEntries(
      genericListEntries.filter(
        (entry) => entry.list_id === selectedGenericList?.id && entry.entry.includes(search.toLowerCase())
      )
    )
  }

  const handleDelete = async () => {
    try {
      if (!activeRow) return
      setLoading(true)
      await deleteGenericList(activeRow.id)
      if (!isMounted()) return
      setDeleteOpen(false)
      setError('')
      populateGenericLists()
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleDeleteEntry = async () => {
    try {
      if (!activeEntry) return
      setLoading(true)
      await deleteGenericListEntries(activeEntry.list_id, activeEntry.entry)
      if (!isMounted()) return
      setDeleteOpenEntry(false)
      setError('')
      populateGenericListsEntries()
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleConfirmMassDelete = async () => {
    try {
      if (!selectedGenericList) return
      setShowMassDelete(false)
      const user_confirm = prompt('Enter the Lists ID to confirm')

      if (user_confirm === selectedGenericList.id) {
        const res = await deleteMassListEntries(selectedGenericList.id)
        alert(res.msg)
        populateGenericListsEntries()
      } else {
        alert("Entered List ID didn't match")
      }
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    }
  }

  const handleCreateList = async (id: string, name: string, enabled: boolean) => {
    try {
      if (id === '' || name === '' || enabled === null) {
        throw new Error('You must fill in all fields')
      }

      setLoading(true)
      await addGenericList(id, name, enabled)
      if (!isMounted()) return
      setListCreateEditOpen(false)
      setError('')
      populateGenericLists()
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleEditList = async (name: string, enabled: boolean) => {
    try {
      if (name === '' || enabled === null) {
        throw new Error('You must fill in all fields')
      }
      if (!activeRow) return
      setLoading(true)
      setError('')
      await editGenericList(activeRow.id, name, enabled)
      populateGenericLists()
      setListCreateEditOpen(false)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const populateGenericListsEntries = useCallback(async () => {
    try {
      setLoading(true)
      if (!isMounted()) return
      const data = await getGenericListsEntries()

      setGenericListEntries(data.genericListsEntries)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      setLoading(false)
    }
  }, [isMounted])

  useEffect(() => {
    if (selectedGenericList) {
      if (search === '') {
        setShownGenericListEntries(genericListEntries.filter((entry) => entry.list_id === selectedGenericList.id))
      } else {
        setShownGenericListEntries(
          genericListEntries.filter(
            (entry) => entry.list_id === selectedGenericList.id && entry.entry.includes(search.toLowerCase())
          )
        )
      }
    }
  }, [selectedGenericList, genericListEntries, search])

  useEffect(() => {
    if (listCategory === 'disabled') {
      setShownGenericList(genericLists.filter((list) => !list.enabled))
    } else {
      setShownGenericList(genericLists.filter((list) => list.enabled))
    }
  }, [listCategory, genericLists])

  const populateGenericLists = useCallback(async () => {
    try {
      const data = await getGenericLists()
      if (!isMounted()) return
      setGenericLists(data.genericLists)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    }
  }, [isMounted])

  const handleClickGenericList = async (row: GenericList) => {
    if (!row) return
    setSelectedGenericList(row)
    if (search === '') {
      setShownGenericListEntries(genericListEntries.filter((entry) => entry.list_id === row.id))
    } else {
      setShownGenericListEntries(
        genericListEntries.filter((entry) => entry.list_id === row.id && entry.entry.includes(search.toLowerCase()))
      )
    }
  }

  const handleOpenListCreate = () => {
    setType('add')
    setListCreateEditOpen(true)
  }

  const handleCloseListCreate = () => {
    setActiveRow(null)
    setError('')
    setListCreateEditOpen(false)
  }

  const handleOpenEntryCreate = () => {
    setActiveEntry(null)
    setEntryCreateOpen(true)
  }

  const handleCloseEntryCreate = () => {
    setActiveEntry(null)
    setError('')
    setEntryCreateOpen(false)
  }

  const handleCreateEntry = async (entry: string) => {
    try {
      if (entry === '') {
        throw new Error('You must fill in all fields')
      }
      if (!selectedGenericList) return
      if (!isMounted()) return
      setLoading(true)
      await addGenericListEntries(selectedGenericList?.id, entry)

      setEntryCreateOpen(false)
      populateGenericListsEntries()
      setError('')
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleUploadCSV = () => {
    if (!selectedGenericList) return
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.csv'
    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0]
      if (!file) return
      const reader = new FileReader()
      reader.onload = async (e) => {
        if (!e.target) return
        const text = e.target.result as string
        const entries = text.split('\n').filter((entry) => entry.trim() !== '')
        if (entries.length === 0) return
        try {
          setLoading(true)
          await Promise.all(entries.map((entry) => addGenericListEntries(selectedGenericList.id, entry)))
          populateGenericListsEntries()
        } catch (err) {
          const { msg } = handleError(err)
          setError(msg)
        } finally {
          setLoading(false)
        }
      }
      reader.readAsText(file)
    }
    input.click()
  }

  const handleChangeListCategory = (e: React.MouseEvent<HTMLElement>, newValue: 'enabled' | 'disabled') => {
    setListCategory(newValue)
    if (newValue === 'disabled') {
      setShownGenericList(genericLists.filter((list) => !list.enabled))
      setShownGenericListEntries([])
      return
    }
    setShownGenericList(genericLists.filter((list) => list.enabled))
    setShownGenericListEntries([])
  }

  useEffect(() => {
    populateGenericLists()
    populateGenericListsEntries()
  }, [populateGenericLists, populateGenericListsEntries])

  return (
    <Grid container justifyContent="center" sx={{ mt: 3 }} padding={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Grid item>
            <Typography variant="h3">Generic Lists</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ mt: 1 }}>
          {error && (
            <Grid item>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent="start" spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 3 }}>
            <Grid container spacing={1} alignItems="center" sx={{ mb: 2 }}>
              <Grid item>
                <ToggleButtonGroup
                  value={listCategory}
                  exclusive
                  onChange={handleChangeListCategory}
                  aria-label="enabled"
                >
                  <ToggleButton value="enabled" aria-label="enabled">
                    Enabled
                  </ToggleButton>
                  <ToggleButton value="disabled" aria-label="disabled">
                    Disabled
                  </ToggleButton>
                </ToggleButtonGroup>
                {user?.role === 0 && (
                  <Fab sx={{ mx: 3 }} color="primary" size="small" disabled={loading} onClick={handleOpenListCreate}>
                    <AddIcon />
                  </Fab>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <GenericListsTable
                admin={user?.role === 0}
                genericLists={shownGenericList}
                loading={loading}
                selected={selectedGenericList}
                dark={darkMode}
                onClick={handleClickGenericList}
                onEdit={handleClickEdit}
                onDelete={handleOpenDeleteList}
              />
            </Grid>
          </Grid>
          {selectedGenericList && (
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 3 }}>
              <Grid container spacing={1} justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <Grid item>
                  {user?.role === 0 && (
                    <Grid container spacing={2}>
                      <Grid item>
                        <Fab
                          sx={{ mx: 2 }}
                          color="primary"
                          size="small"
                          disabled={loading}
                          onClick={handleOpenEntryCreate}
                        >
                          <AddIcon />
                        </Fab>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" onClick={handleUploadCSV}>
                          Upload CSV
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" color="error" onClick={handleOpenMassDelete}>
                          Mass Delete
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item>
                  <SearchBar
                    button
                    value={search}
                    dark={!darkMode}
                    onChange={handleChangeSearch}
                    onSubmit={handleSubmitSearch}
                    onClickButton={handleSubmitSearch}
                  />
                </Grid>
              </Grid>
              <GenericListsEntryTable
                admin={user?.role === 0}
                entries={shownGenericListEntries}
                loading={loading}
                onDelete={handleOpenDeleteEntry}
              />
            </Grid>
          )}
        </Grid>
        <DeleteConfirmDialog
          open={deleteOpen}
          name={deleteName}
          loading={loading}
          error={error}
          onClose={handleDeleteClose}
          onDelete={handleDelete}
        />
        <DeleteConfirmDialog
          open={deleteOpenEntry}
          name={deleteNameEntry}
          loading={loading}
          error={error}
          onClose={handleDeleteCloseEntry}
          onDelete={handleDeleteEntry}
        />
        <DeleteConfirmDialog
          open={showMassDelete}
          name={deleteName}
          loading={loading}
          error={error}
          onClose={handleCloseMassDelete}
          onDelete={handleConfirmMassDelete}
        />
        <AddEditGenericList
          open={listCreateEditOpen}
          onClose={handleCloseListCreate}
          error={error}
          onClickAdd={handleCreateList}
          type={type}
          onClickEdit={handleEditList}
          existingRow={activeRow}
        />
        <AddEditGenericListEntry
          open={entryCreateOpen}
          onClose={handleCloseEntryCreate}
          error={error}
          onClickAdd={handleCreateEntry}
        />
      </Grid>
    </Grid>
  )
}

export default GenericLists
