import { Alert, Fab, Grid, Typography } from '@mui/material'
import useStore from '../util/Store.tsx'
import AddIcon from '@mui/icons-material/Add'
import { useCallback, useEffect, useState } from 'react'
import SearchBar from '../components/SearchBar.tsx'
import { addUserAccount, deleteUserAccount, editUserAccount, getUserAccounts, UserAccounts } from '../util/Api.tsx'
import UserTable from '../components/UserTable.tsx'
import UserEdit from '../components/UserEdit.tsx'
import DeleteConfirmDialog from '../components/DeleteConfirmDialog.tsx'

const UserAdmin = () => {
  const [user] = useStore('user')
  const [loading, setLoading] = useState(false)
  const [darkMode] = useStore('darkMode')
  const [search, setSearch] = useState('')
  const [error, setError] = useState('')
  const [users, setUsers] = useState<UserAccounts[]>([])
  const [shownUsers, setShownUsers] = useState<UserAccounts[]>([])
  const [createOpen, setCreateOpen] = useState(false)
  const [editing, setEditing] = useState(false)
  const [editingUser, setEditingUser] = useState<UserAccounts | null>(null)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleOpenCreateDialog = () => {
    setCreateOpen(true)
    setEditingUser(null)
  }

  const handleCloseCreateDialog = () => {
    setCreateOpen(false)
    setEditing(false)
    setEditingUser(null)
  }

  const handleEditUser = (user: UserAccounts) => {
    setEditing(true)
    setEditingUser(user)
    setCreateOpen(true)
  }

  const handleDeleteOpen = (user: UserAccounts) => {
    setEditingUser(user)
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setEditingUser(null)
  }

  const handleCreateUser = async (email: string, role: number) => {
    try {
      setLoading(true)
      if (editingUser) {
        await editUserAccount(email, role)
      } else {
        await addUserAccount(email, role)
      }
      const newUsers = await getUserAccounts()
      setUsers(newUsers.users)
      setShownUsers(newUsers.users)
      setEditing(false)
      setEditingUser(null)
      setCreateOpen(false)
      setError('')
    } catch (e: any) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true)
      if (!editingUser) return
      if (editingUser.role === 0) {
        setError('Cannot delete and Admin')
        return
      }
      await deleteUserAccount(editingUser.email)
      const newUsers = await getUserAccounts()
      setUsers(newUsers.users)
      setShownUsers(newUsers.users)
      setDeleteOpen(false)
    } catch (e: any) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const populateUsers = useCallback(async () => {
    setLoading(true)
    try {
      const newUsers = await getUserAccounts()
      setUsers(newUsers.users)
      setShownUsers(newUsers.users)
    } catch (e: any) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    populateUsers()
  }, [populateUsers])

  const handleSubmitSearch = () => {
    setShownUsers(users.filter((u) => u.email.includes(search)))
  }

  return (
    <div>
      {user?.role === 0 ? (
        <Grid container justifyContent="center" sx={{ mt: 3 }} padding={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
            <Grid container justifyContent="center" sx={{ mt: 3 }}>
              <Grid item>
                <Typography variant="h3">User Administration</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" padding={1} spacing={2} sx={{ mt: 3, mb: 3 }}>
              {user?.role === 0 && (
                <Grid item>
                  <Fab color="primary" size="small" disabled={loading} onClick={handleOpenCreateDialog}>
                    <AddIcon />
                  </Fab>
                </Grid>
              )}
              <Grid item>
                <SearchBar
                  value={search}
                  dark={!darkMode}
                  button
                  onChange={handleChangeSearch}
                  onSubmit={handleSubmitSearch}
                  onClickButton={handleSubmitSearch}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              {error && (
                <Grid item>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent="start" spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <UserTable
                  loading={loading}
                  shownUsers={shownUsers}
                  onEdit={handleEditUser}
                  onDelete={handleDeleteOpen}
                />
                <UserEdit
                  open={createOpen}
                  onClose={handleCloseCreateDialog}
                  onSave={handleCreateUser}
                  users={users}
                  loading={loading}
                  edit={editing}
                  user={editingUser}
                />
              </Grid>
            </Grid>
          </Grid>
          <DeleteConfirmDialog
            open={deleteOpen}
            onClose={handleDeleteClose}
            onDelete={handleDeleteConfirm}
            loading={loading}
            name={editingUser?.email}
          />
        </Grid>
      ) : (
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Grid item>
            <Typography variant="h3">Nice try, your account has now been flagged</Typography>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default UserAdmin
