import React, { useState, useCallback, useEffect } from 'react'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import CheckIcon from '@mui/icons-material/Check'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTheme } from '@mui/material/styles'
import useIsMounted from 'react-is-mounted-hook'
import { useHistory } from 'react-router-dom'
import RefreshIcon from '@mui/icons-material/Refresh'
import useMediaQuery from '@mui/material/useMediaQuery'

import { getURLInfoQuick, SignatureUrlInfo } from '../util/Api.tsx'
import handleError from '../util/Error.tsx'

const FONT_SIZE = 14
const LINE_HEIGHT = 1.68

const stripUrl = (url: string) => {
  if (typeof url !== 'string') throw new Error('Url must be a string')
  let newUrl = url
  if (newUrl.substring(0, 8) === 'https://') {
    newUrl = newUrl.substring(8)
  } else if (newUrl.substring(0, 7) === 'http://') {
    newUrl = newUrl.substring(7)
  }
  if (url.split('.').some((str) => isNaN(parseInt(str)))) {
    const splitUrl = newUrl.split('/')
    newUrl = splitUrl[0]
  }
  return newUrl
}

interface UrlImportDialogProps {
  open?: boolean
  loading?: boolean
  erroredUrls?: string[]
  signatureId: string
  secondary?: boolean
  onClose?: () => void
  onSubmit?: (urlsInfo: SignatureUrlInfo[]) => void
}

const UrlImportDialog = ({
  open = false,
  loading = false,
  erroredUrls = [],
  signatureId = '',
  secondary = false,
  onClose = () => {},
  onSubmit = () => {},
}: UrlImportDialogProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()
  const history = useHistory()
  const sm = useMediaQuery('(min-width:600px)')

  const [urls, setUrls] = useState('')
  const [urlsInfo, setUrlsInfo] = useState<SignatureUrlInfo[]>([])
  const [error, setError] = useState('')
  const [infoLoading, setInfoLoading] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const getUrlStrings = (urlstr: string) => {
    return urlstr
      .trim()
      .split('\n')
      .map((s) => s.trim())
  }

  const handleClose = () => {
    if (infoLoading || loading) return
    onClose()
  }

  const handleChangeUrls = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrls(e.target.value)
    setShowConfirm(false)
    if (e.target.value.length === 0) {
      return setUrlsInfo([])
    }
    if (urlsInfo.length === 0) {
      return
    }
    const urls = getUrlStrings(e.target.value)
    const newUrlsInfo = urls.map((url) => {
      const urlInfo = urlsInfo.find((u) => (secondary ? u.url === url && u.signatureId === signatureId : u.url === url))
      if (!urlInfo) {
        return {
          url,
          signatureId: null,
          unknown: true,
          primarySig: secondary ? false : true,
        }
      }
      return {
        ...urlInfo,
      }
    })
    setUrlsInfo(newUrlsInfo)
  }

  const handleNavigateSignature = (id: string) => {
    if (infoLoading || loading) return
    history.push(`/signature/${id}/${signatureId}`)
    onClose()
  }

  const calcTextInfo = useCallback(() => {
    return sm
      ? {
          textboxWidth: 340,
          maxChars: 36,
        }
      : {
          textboxWidth: 200,
          maxChars: 20,
        }
  }, [sm])

  const calcUrlSpaces = useCallback(
    (url: string) => {
      const lineInfo = calcTextInfo()
      const spaces = Math.ceil(url.length / lineInfo.maxChars) - 1
      return spaces
    },
    [calcTextInfo]
  )

  const getUrlInfoObj = async (url: string) => {
    try {
      const { exact, valid } = await getURLInfoQuick(url)
      const foundExact = exact.find((ext) => ext.signatureId === signatureId)
      if (foundExact) {
        return {
          signatureId: foundExact.signatureId,
          primarySig: foundExact.primarySig,
          url,
          valid,
        }
      }

      const primary = exact.find((ext) => ext.primarySig === true)

      if (primary) {
        return {
          signatureId: primary.signatureId,
          primarySig: primary.primarySig,
          url,
          valid,
        }
      }

      const urlCheck = url.includes('www.') ? url.substring(4) : 'www.' + url
      const { exact: exact2, valid: valid2 } = await getURLInfoQuick(urlCheck)

      const foundExact2 = exact2.find((ext) => ext.signatureId === signatureId)
      if (foundExact2) {
        return {
          signatureId: foundExact2.signatureId,
          primarySig: foundExact2.primarySig,
          url,
          valid2,
        }
      }

      const primary2 = exact2.find((ext) => ext.primarySig === true)

      if (primary2) {
        return {
          signatureId: primary2.signatureId,
          primarySig: primary2.primarySig,
          url,
          valid2,
        }
      }

      return {
        signatureId: null,
        url,
        valid,
        primarySig: secondary ? false : true,
      }
    } catch (err) {
      const { msg } = handleError(err)
      return {
        signatureId: null,
        url,
        error: msg,
        valid: true,
        primarySig: secondary ? false : true,
      }
    }
  }

  useEffect(() => {
    setUrls('')
    setUrlsInfo([])
  }, [secondary])

  const getUrlsExistAlready = () => {
    return urlsInfo.some((urlInfo) => urlInfo.signatureId === signatureId)
  }

  const getUrlResultsList = useCallback(() => {
    if (urlsInfo.length === 0) return []
    return urlsInfo.map((urlInfo) => ({
      ...urlInfo,
      spaces: calcUrlSpaces(urlInfo.url),
    }))
  }, [calcUrlSpaces, urlsInfo])

  const handlePopulateUrlsInfo = useCallback(async () => {
    try {
      if (!urls) return
      const splitUrls = getUrlStrings(urls).filter(Boolean).map(stripUrl)
      if (splitUrls.length > 200) {
        throw new Error('You may not classify more than 200 urls at once')
      }
      setUrls(splitUrls.join('\n'))
      setInfoLoading(true)
      const results = await Promise.all(splitUrls.map(getUrlInfoObj))
      setUrlsInfo(results)
      if (!results.some((u) => u.error)) {
        setShowConfirm(true)
      }
      const dupecheck: string[] = []
      const removeDuplicates = results.filter((e) => {
        const dupe = dupecheck.includes(e.url)
        if (!dupe) {
          dupecheck.push(e.url)
          return true
        }
        return false
      })
      setUrlsInfo(removeDuplicates)
      setUrls(dupecheck.join('\n'))
      if (results.some((u) => !u.valid)) {
        setError('Invalid url detected')
      } else {
        setError('')
      }
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setInfoLoading(false)
    }
  }, [isMounted, urls, getUrlInfoObj])

  const handleConfirm = () => {
    onSubmit(urlsInfo)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Import {secondary && 'Secondary'} URLs
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={Boolean(error)}>
            <Box>
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                    {!error
                      ? ' '
                      : error.includes('\n')
                      ? error.split('\n').map((err) => (
                          <>
                            <br />
                            {err}
                          </>
                        ))
                      : error}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Box sx={{ maxHeight: 600, overflowY: 'scroll' }}>
            <Grid container padding={1} spacing={1} justifyContent="center">
              {getUrlResultsList().length !== 0 && (
                <Grid item zeroMinWidth>
                  <Box sx={{ mb: 3 }}></Box>
                  <Grid container maxWidth={155} direction="column" wrap="nowrap">
                    {getUrlResultsList().map((info, index) => (
                      <Grid item zeroMinWidth key={`url-recat-${index}`}>
                        {erroredUrls.includes(info.url) ? (
                          <Typography noWrap fontSize={FONT_SIZE} lineHeight={LINE_HEIGHT}>
                            <CloseIcon color="error" sx={{ fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, mr: 1 }} />
                            Unexpected Error
                          </Typography>
                        ) : info.error ? (
                          <Typography noWrap fontSize={FONT_SIZE} lineHeight={LINE_HEIGHT}>
                            <CloseIcon color="warning" sx={{ fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, mr: 1 }} />
                            {info.error}
                          </Typography>
                        ) : info.valid === false ? (
                          <Typography noWrap fontSize={FONT_SIZE} lineHeight={LINE_HEIGHT}>
                            <CloseIcon color="warning" sx={{ fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, mr: 1 }} />
                            Invalid domain name
                            <Typography
                              display="inline"
                              component="span"
                              fontSize={FONT_SIZE}
                              lineHeight={LINE_HEIGHT}
                              noWrap
                              sx={{ cursor: 'pointer' }}
                              color="primary"
                              onClick={() => handleNavigateSignature(info?.signatureId || '')}
                            >
                              {info?.signatureId?.replace?.('sphirewall.application.', '')?.toUpperCase?.()}
                            </Typography>
                          </Typography>
                        ) : info.unknown ? (
                          <Typography noWrap fontSize={FONT_SIZE} lineHeight={LINE_HEIGHT}>
                            <CloseIcon color="warning" sx={{ fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, mr: 1 }} />
                            ???
                          </Typography>
                        ) : info?.similar && info.signatureId ? (
                          <Typography noWrap fontSize={FONT_SIZE} lineHeight={LINE_HEIGHT}>
                            <CloseIcon color="warning" sx={{ fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, mr: 1 }} />
                            Similar URL exists{' '}
                            <Tooltip placement="left" title={info.signatureId}>
                              <Typography
                                fontSize={FONT_SIZE}
                                lineHeight={LINE_HEIGHT}
                                noWrap
                                sx={{ cursor: 'pointer' }}
                                color="primary"
                                onClick={() => handleNavigateSignature(info?.signatureId || '')}
                              >
                                <RefreshIcon
                                  color="primary"
                                  sx={{ fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, mr: 1 }}
                                />
                                {info.signatureId.replace('sphirewall.application.', '').toUpperCase()}
                              </Typography>
                            </Tooltip>
                          </Typography>
                        ) : info.signatureId && info.signatureId !== signatureId ? (
                          <Tooltip placement="left" title={info.signatureId}>
                            <Typography
                              fontSize={FONT_SIZE}
                              lineHeight={LINE_HEIGHT}
                              noWrap
                              sx={{ cursor: 'pointer' }}
                              color="primary"
                              onClick={() => handleNavigateSignature(info?.signatureId || '')}
                            >
                              <RefreshIcon
                                color="primary"
                                sx={{ fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, mr: 1 }}
                              />
                              {info.signatureId.replace('sphirewall.application.', '').toUpperCase()}
                            </Typography>
                          </Tooltip>
                        ) : info.signatureId !== signatureId && !info?.error ? (
                          <Typography noWrap fontSize={FONT_SIZE} lineHeight={LINE_HEIGHT}>
                            <CheckIcon color="primary" sx={{ fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, mr: 1 }} />
                            Doesn't exist
                          </Typography>
                        ) : !info?.error && !secondary && info.primarySig === false ? (
                          <Typography noWrap fontSize={FONT_SIZE} lineHeight={LINE_HEIGHT}>
                            <CloseIcon color="warning" sx={{ fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, mr: 1 }} />
                            Exists here (Not Primary)
                          </Typography>
                        ) : !info?.error ? (
                          <Typography noWrap fontSize={FONT_SIZE} lineHeight={LINE_HEIGHT}>
                            <CloseIcon color="warning" sx={{ fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, mr: 1 }} />
                            Exists here
                          </Typography>
                        ) : (
                          <Typography noWrap fontSize={FONT_SIZE} lineHeight={LINE_HEIGHT}>
                            <CloseIcon color="error" sx={{ fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, mr: 1 }} />
                            Unexpected Errored
                          </Typography>
                        )}
                        {info.spaces !== 0 && <Box sx={{ height: LINE_HEIGHT * FONT_SIZE * info.spaces + 'px' }} />}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              <Grid item>
                <Box sx={{ mb: 1 }}></Box>
                <TextField
                  multiline
                  placeholder={`google.com\nhello.com\nreports.google.com`}
                  value={urls}
                  onChange={handleChangeUrls}
                  minRows={10}
                  disabled={infoLoading || loading}
                  inputProps={{
                    sx: { fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, fontFamily: 'Roboto, monospace' },
                  }}
                  sx={{ width: calcTextInfo().textboxWidth }}
                />
              </Grid>
            </Grid>
          </Box>

          {getUrlResultsList().length !== 0 && getUrlsExistAlready() && (
            <Grid container padding={1} justifyContent="center" alignItems="center" direction="column">
              <Grid item>
                <Typography>URLs already exist in this signature</Typography>
              </Grid>
            </Grid>
          )}
          <Grid container padding={1} justifyContent="center">
            {!showConfirm ? (
              <Grid item>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={infoLoading || loading}
                  disabled={infoLoading || loading || !urls}
                  onClick={handlePopulateUrlsInfo}
                >
                  Check URLs
                </LoadingButton>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={infoLoading || loading || Boolean(error)}
                  onClick={handleConfirm}
                  color={error ? 'error' : 'primary'}
                >
                  Confirm Import
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default UrlImportDialog
