import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useIsMounted from 'react-is-mounted-hook'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Paper from '@mui/material/Paper'
import LinearProgress from '@mui/material/LinearProgress'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Fab from '@mui/material/Fab'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import CopyIcon from '@mui/icons-material/ContentCopy'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckIcon from '@mui/icons-material/Check'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import URLTable from '../URLTable.tsx'
import DependencyTable from '../DependencyTable.tsx'
import LazyImage from '../LazyImage.tsx'
import ReclassifyDialog from '../ReclassifyDialog.tsx'
import AddDependencyDialog from '../AddDependencyDialog.tsx'
import RecatDialog from '../RecatDialog.tsx'
import SyncDialog from '../SyncDialog.tsx'
import MappingsTable from '../MappingsTable.tsx'
import DeleteConfirmDialog from '../DeleteConfirmDialog.tsx'
import AppTable from '../AppTable.tsx'
import AddApplicationDialog from '../AddApplicationDialog.tsx'

import useStore from '../../util/Store.tsx'

import {
  deleteSignature,
  editSignature,
  addSignatureCriteria,
  deleteSignatureCriteria,
  addSignatureNECriteria,
  deleteSignatureNECriteria,
  deleteSignatureURL,
  addSignatureDependency,
  deleteSignatureDependency,
  getSignature,
  getSignatures,
  getSignatureURLs,
  getAllSignatureURLs,
  getSignatureMappings,
  deleteMapping,
  fetchFavicons,
  deleteSignatureApp,
  addSignatureApp,
  manualSyncLivePublish,
  Signature,
  Url,
  Mapping,
  getControlledAppId,
  editAppListing,
  promoteUrls,
} from '../../util/Api.tsx'
import handleError from '../../util/Error.tsx'
import { downloadFile, convertToCSV, getBase64 } from '../../util/Func.tsx'
import TagsEdit from '../TagsEdit.tsx'
import ConfirmDialog from '../ConfirmDialog.tsx'

interface Criteria {
  id: string
  criteria: { [key: string]: any }
}

interface SignatureDetailsProps {
  signatureId: string
  onExit?: () => void
}

const SignatureDetails = ({ signatureId = '', onExit = () => {} }: SignatureDetailsProps) => {
  const isMounted = useIsMounted()
  const history = useHistory()

  const [darkMode] = useStore('darkMode')
  const [user] = useStore('user')

  const [loading, setLoading] = useState(false)
  const [originalSignature, setOriginalSignature] = useState<Signature | null>(null)
  const [signature, setSignature] = useState<Signature | null>(null)
  const [urls, setUrls] = useState<Url[]>([])
  const [urlsTotal, setUrlsTotal] = useState(0)
  const [urlsPage, setUrlsPage] = useState(1)
  const [urlsLoading, setUrlsLoading] = useState(false)
  const [signatures, setSignatures] = useState<Signature[]>([])
  const [syncLabel, setSyncLabel] = useState('')
  const [syncOpen, setSyncOpen] = useState(false)
  const [mappingsLoading, setMappingsLoading] = useState(false)
  const [mappings, setMappings] = useState<Mapping[]>([])
  const [mappingsOpen, setMappingsOpen] = useState(false)
  const [activeMapping, setActiveMapping] = useState<Mapping | null>(null)
  const [deleteMappingOpen, setDeleteMappingOpen] = useState(false)
  const [deleteSignatureOpen, setDeleteSignatureOpen] = useState(false)
  const [viewOnlyMode, setViewOnlyMode] = useState(false)
  const [selectedTags, setSelectedTags] = useState<string[]>([])

  const fileUpload = useRef<HTMLInputElement | null>(null)
  const [faviconDataUrl, setfaviconDataUrl] = useState<null | string>(null)
  const [autoCategory, setAutoCategory] = useState(true)
  const [criteria, setCriteria] = useState<Criteria[]>([])

  const [criteriaError, setCriteriaError] = useState('')
  const [urlError, setUrlError] = useState('')
  const [dependencyError, setDependencyError] = useState('')
  const [appError, setAppError] = useState('')

  const [criteriaOpen, setCriteriaOpen] = useState(false)
  const [urlsOpen, setUrlsOpen] = useState(false)
  const [dependenciesOpen, setDependenciesOpen] = useState(false)
  const [exampleOpen, setExampleOpen] = useState(false)
  const [activeControlApp, setActiveControlApp] = useState('')

  const [activeUrl, setActiveUrl] = useState('')
  const [reclassifyOpen, setReclassifyOpen] = useState(false)

  const [promoteOpen, setPromoteOpen] = useState(false)

  const [addUrlOpen, setAddUrlOpen] = useState(false)
  const [addDependencyOpen, setAddDependencyOpen] = useState(false)
  const [secondaryURLs, setsecondaryURLs] = useState(false)
  const [activeAppID, setActiveAppID] = useState('')
  const [activeType, setActiveType] = useState('')
  const [deleteApplicationOpen, setDeleteApplicationOpen] = useState(false)
  const [addAppStoreOpen, setAddAppStoreOpen] = useState(false)
  const [appStoreOpen, setAppStoreOpen] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [hovering, setHovering] = useState<number | null>(null)

  const [deleteCriteria, setDeleteCriteria] = useState<Criteria | null>(null)
  const [deleteNECriteria, setDeleteNECriteria] = useState<Criteria | null>(null)

  const handleOpenCriteriaDelete = (newCriteria: Criteria) => {
    setDeleteCriteria(newCriteria)
  }

  const handleOpenNECriteriaDelete = (newCriteria: Criteria) => {
    setDeleteNECriteria(newCriteria)
  }

  const handleCloseCriteriaDelete = () => {
    setDeleteCriteria(null)
  }

  const handleCloseNECriteriaDelete = () => {
    setDeleteNECriteria(null)
  }

  const handleCloseDeleteMapping = () => {
    setDeleteMappingOpen(false)
  }

  const handleClickPromote = (url: string) => {
    setActiveUrl(url)
    setPromoteOpen(true)
  }

  const handleClosePromote = () => {
    setActiveUrl('')
    setPromoteOpen(false)
  }

  const handleOpenDeleteMapping = (mapping: Mapping) => {
    setActiveMapping(mapping)
    setDeleteMappingOpen(true)
  }

  const handleDeleteMapping = async () => {
    try {
      if (!activeMapping?.signatureId) return
      setMappingsLoading(true)
      const { mappings: newMappings } = await deleteMapping({
        signatureId: activeMapping.signatureId,
        providerId: activeMapping.providerId,
        providerCategory: activeMapping.providerCategory,
      })
      if (!isMounted()) return

      setMappings(newMappings.filter((mapping) => mapping.signatureId === activeMapping.signatureId))
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setMappingsLoading(false)
      setDeleteMappingOpen(false)
    }
  }

  const handleCloseDeleteSignature = () => {
    setDeleteSignatureOpen(false)
  }

  const handleOpenDeleteSignature = () => {
    setDeleteSignatureOpen(true)
  }

  const handleDeleteSignature = async () => {
    try {
      if (!signature) return
      const user_confirm = prompt("Enter the signature's Display Name to confirm")

      if (user_confirm === signature.name) {
        const res = await deleteSignature(signature.id)
        alert(res.msg)
        if (res.msg === 'Signature has been deleted') {
          onExit()
        }
      } else {
        alert("Entered Signature name didn't match")
      }
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setDeleteSignatureOpen(false)
    }
  }

  const handleOpenDeleteApplication = (appId: string, type: string, control: string) => {
    setActiveAppID(appId)
    setActiveType(type)
    setActiveControlApp(control)
    setDeleteApplicationOpen(true)
  }

  const handleCloseDeleteApplication = () => {
    setDeleteApplicationOpen(false)
  }

  const handleDeleteApp = async () => {
    try {
      if (!signature) return
      setLoading(true)
      if (activeControlApp !== '') {
        const app = await getControlledAppId(activeControlApp)
        if (app.app?.category === signature.id) {
          await editAppListing(app.app.id, app.app.name, app.app.favicon || '', '')
        }
      } else {
        await deleteSignatureApp('', signature.id, activeAppID, activeType)
      }
      populateSignature()
      if (!isMounted()) return
      setAppError('')
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setAppError(msg)
    } finally {
      if (!isMounted()) return
      setDeleteApplicationOpen(false)
      setActiveAppID('')
      setActiveType('')
      setActiveControlApp('')
      setLoading(false)
    }
  }

  const handleExportSignature = async () => {
    try {
      if (!signature) return

      setExportLoading(true)
      const { entries } = await getAllSignatureURLs(signature.id)
      const headerLines = Object.entries(signature).map(
        ([key, value]) => `${key},${Array.isArray(value) ? value.length : value.toString()}`
      )
      const header = headerLines.reduce((a, b) => `${a}\n${b}`, 'SIGNATURE:\n') + '\n\nURLS:\n\n'
      const allUrls = entries.length !== 0 ? convertToCSV(entries) : ''
      downloadFile(signature.id, header + allUrls, 'csv')
    } catch (err) {
      handleError(err)
    } finally {
      setExportLoading(false)
    }
  }

  const handleCriteriaAccordionClick = () => {
    setCriteriaOpen(!criteriaOpen)
  }

  const handleUrlAccordionClick = () => {
    setUrlsOpen(!urlsOpen)
  }

  const handleDependencyAccordionClick = () => {
    setDependenciesOpen(!dependenciesOpen)
  }

  const handleAppAccordionClick = () => {
    setAppStoreOpen(!appStoreOpen)
  }

  const handleMappingsAccordionClick = () => {
    setMappingsOpen(!mappingsOpen)
  }

  const handleClickSignature = (dependency: string, e?: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
    if (!dependency) return
    if (!signature) return
    if (e) e.preventDefault()
    history.push(`/signature/${dependency}/${signature.id}`)
  }

  const handleDeleteDependency = useCallback(
    async (delDependency: string) => {
      try {
        if (!signature) return

        setLoading(true)
        const res = await deleteSignatureDependency(signature.id, delDependency)
        setSignature(res.signature)
        setDependencyError('')
      } catch (err) {
        const { msg } = handleError(err)
        setDependencyError(msg)
      } finally {
        setLoading(false)
      }
    },
    [signature]
  )

  const handleAddApplication = async (appid: string, type: string) => {
    try {
      if (!signature) return

      setLoading(true)
      await addSignatureApp('', signature.id, appid, type)
      populateSignature()
      if (!isMounted()) return
      setAppError('')
      setAddAppStoreOpen(false)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setAppError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleAddDependency = useCallback(
    async (dependency: string | null) => {
      try {
        if (!signature) return
        if (!dependency) return

        setLoading(true)
        if (signature.dependencies.includes(dependency)) throw Error('Dependency already exists')
        const res = await addSignatureDependency(signature.id, dependency)
        setSignature(res.signature)
        setDependencyError('')
        setAddDependencyOpen(false)
      } catch (err) {
        const { msg } = handleError(err)
        setDependencyError(msg)
      } finally {
        setLoading(false)
      }
    },
    [signature]
  )

  const handleDeleteURL = useCallback(
    async (delUrl: string) => {
      try {
        if (!signature) return

        setLoading(true)
        await deleteSignatureURL(signature.id, delUrl)
        setUrls(urls.filter((url) => url.website !== delUrl))
        setUrlError('')
      } catch (err) {
        const { msg } = handleError(err)
        setUrlError(msg)
      } finally {
        setLoading(false)
      }
    },
    [signature, urls]
  )

  const handleEditCriteria = (e: { jsObject: any }) => {
    if (typeof e.jsObject !== 'object') {
      setCriteriaError('Criteria must be an array')
      return
    }
    if (!Array.isArray(e.jsObject)) {
      setCriteriaError('Criteria must be an array')
      return
    }
    setCriteria(e.jsObject)
    setCriteriaError('')
  }

  const handleCriteriaAdd = useCallback(async () => {
    try {
      if (!signature) return

      setLoading(true)
      const res = await addSignatureCriteria(signature.id, criteria)
      setSignature(res.signature)
    } catch (err) {
      const { msg } = handleError(err)
      setCriteriaError(msg)
    } finally {
      setLoading(false)
    }
  }, [signature, criteria])

  const handleDeleteCriteria = useCallback(async () => {
    try {
      if (!signature) return

      if (!deleteCriteria) return
      setLoading(true)

      const res = await deleteSignatureCriteria(signature.id, deleteCriteria.id)
      setSignature(res.signature)
      setDeleteCriteria(null)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      setLoading(false)
    }
  }, [signature, deleteCriteria])

  const handleNECriteriaAdd = useCallback(async () => {
    try {
      if (!signature) return

      setLoading(true)
      const res = await addSignatureNECriteria(signature.id, criteria)
      setSignature(res.signature)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      setLoading(false)
    }
  }, [signature, criteria])

  const handleDeleteNECriteria = useCallback(async () => {
    try {
      if (!signature) return

      if (!deleteNECriteria) return
      setLoading(true)

      const res = await deleteSignatureNECriteria(signature.id, deleteNECriteria.id)
      setSignature(res.signature)
      setDeleteNECriteria(null)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      setLoading(false)
    }
  }, [signature, deleteNECriteria])

  const handleFetchFavicon = useCallback(async () => {
    try {
      if (!signature) return

      setLoading(true)
      await fetchFavicons(signature.id)
      if (!isMounted()) return
      setSignature({ ...signature })
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [signature, isMounted])

  const handleClearFileUpload = useCallback(() => {
    if (!fileUpload.current) return
    if (!originalSignature) return
    if (!signature) return
    setfaviconDataUrl(null)
    setSignature({ ...signature, faviconUrl: originalSignature?.faviconUrl || null })
    fileUpload.current.value = ''
  }, [signature, originalSignature])

  const handleChangeUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!signature) return
      if (!e?.target?.files) return

      if (e.target.files.length === 0) {
        return setfaviconDataUrl(null)
      }
      const dataUrl = await getBase64(e.target.files[0])
      setSignature({ ...signature, faviconUrl: dataUrl })
      return setfaviconDataUrl(dataUrl)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    }
  }

  const handleEditCategory = (value: string | null) => {
    if (!signature) return
    setSignature({
      ...signature,
      category: value,
    })
  }

  const handleEditSignature = (
    field: keyof Signature,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!signature) return
    setSignature({
      ...signature,
      [field]: e.target.value,
    })
  }

  const handleToggleEditSignature = (field: keyof Signature) => {
    if (!signature) return
    if (field === 'enabled' && signature.enabled) {
      const isDependency = signatures.some((s: Signature) => {
        if (s.dependencies?.includes(signature.id)) {
          alert(s.id + ' has this signature as a depedenancy')
          return true
        }
        return false
      })
      if (isDependency) return
    }

    setSignature({
      ...signature,
      [field]: !Boolean(signature[field]),
    })
  }

  const handleChangeTags = (newValue: string[]) => {
    setSelectedTags(newValue)
    if (!signature) return
    setSignature({
      ...signature,
      tags: newValue,
    })
  }

  const getCategoryType = () => {
    return signature?.isSubCategory ? 'subCategory' : signature?.isCategory ? 'category' : 'signature'
  }

  const handleChangeCategoryType = (
    e: React.MouseEvent<HTMLElement>,
    value: 'category' | 'subCategory' | 'signature'
  ) => {
    if (!signature) return
    setSignature({
      ...signature,
      isSubCategory: value === 'subCategory',
      isCategory: value === 'category',
    })
  }

  const handleSetAutoCategory = () => {
    if (!signature) return
    if (!autoCategory) {
      setSignature({
        ...signature,
        isSubCategory: signature.isSubCategory,
        isCategory: signature.isCategory,
      })
    }
    setAutoCategory(!autoCategory)
  }

  const handleOpenReclassify = (url: string) => {
    setActiveUrl(url)
    setReclassifyOpen(true)
  }

  const handleConfirmPromote = async () => {
    try {
      if (!activeUrl) return
      setLoading(true)
      await promoteUrls(activeUrl, signatureId)
      if (!isMounted()) return
      alert('URL promoted')
      await populateUrls()
    } catch (e) {
      alert('Failed to promote URL')
    } finally {
      setLoading(false)
      setPromoteOpen(false)
    }
  }

  const handleCloseReclassify = () => {
    setReclassifyOpen(false)
  }

  const handleAfterReclassify = (newSignature: string) => {
    setReclassifyOpen(false)
    history.push(`/signature/${newSignature}`)
  }

  const handleToggleAddURL = () => {
    if (!addUrlOpen) setUrlError('')
    setAddUrlOpen(!addUrlOpen)
    setsecondaryURLs(false)
  }

  const handleToggleAddSecondary = () => {
    if (!addUrlOpen) setUrlError('')
    setAddUrlOpen(!addUrlOpen)
    setsecondaryURLs(true)
  }

  const handleToggleAddDependency = () => {
    if (!addDependencyOpen) setDependencyError('')
    setAddDependencyOpen(!addDependencyOpen)
  }

  const handleToggleAddApp = () => {
    if (!addAppStoreOpen) setAppError('')
    setAddAppStoreOpen(!addAppStoreOpen)
  }

  const handleManualSync = async () => {
    try {
      setLoading(true)
      const res = await manualSyncLivePublish()
      alert(res.msg)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleSaveSignature = useCallback(async () => {
    try {
      if (!signature) return

      setLoading(true)

      const newSignature = {
        id: String(signature.id),
        category: String(signature.category || ''),
        description: String(signature.description || ''),
        enabled: Boolean(signature.enabled),
        name: String(signature.name),
        noise: Boolean(signature.noise),
        url: String(signature.url || ''),
        isSubCategory: Boolean(signature.isSubCategory),
        isCategory: Boolean(signature.isCategory),
        autoCategory,
        faviconDataUrl,
        tags: signature.tags,
        focus: Boolean(signature.focus),
        reporting: Boolean(signature.reporting),
        hidden: Boolean(signature.hidden),
      }

      const res = await editSignature(newSignature.id, newSignature)

      handleClearFileUpload()
      setOriginalSignature(res.signature)
      setSignature(res.signature)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      setLoading(false)
    }
  }, [signature, autoCategory, faviconDataUrl, handleClearFileUpload])

  const handleToggleSync = () => {
    setSyncOpen(!syncOpen)
  }

  const handleGetSignatureCategoryIds = useCallback(() => {
    if (!signature) return []

    if (signatures.length === 0) {
      return [signature.category]
    }
    return [...signatures.filter((sig) => sig.isSubCategory || sig.isCategory).map((sig) => sig.id)]
  }, [signatures, signature])

  const handleLoadMoreURLs = async () => {
    try {
      setUrlsLoading(true)
      const nextPage = urlsPage + 1
      const res = await getSignatureURLs(signatureId, nextPage)
      if (!isMounted()) return
      setUrls([...urls, ...res.entries])
      setUrlsPage(nextPage)
    } catch (err) {
      handleError(err)
    } finally {
      if (!isMounted()) return
      setUrlsLoading(false)
    }
  }

  const handleExampleExpanded = () => {
    setExampleOpen(!exampleOpen)
  }

  const isModified = useCallback(() => {
    return JSON.stringify(originalSignature) !== JSON.stringify(signature)
  }, [originalSignature, signature])

  const handleToggleViewOnly = () => {
    setViewOnlyMode(!viewOnlyMode)
  }

  const populateMappings = useCallback(async () => {
    try {
      setMappingsLoading(true)
      const res = await getSignatureMappings(signatureId)
      if (!isMounted()) return
      if (res.mappings.length !== 0) {
        setMappingsOpen(true)
      } else {
        setMappingsOpen(false)
      }
      setMappings(res.mappings)
    } catch (err) {
      handleError(err)
    } finally {
      setMappingsLoading(false)
    }
  }, [isMounted, signatureId])

  const populateSignatures = useCallback(async () => {
    try {
      const res = await getSignatures()
      if (!isMounted()) return
      setSignatures(res.signatures)
    } catch (err) {
      handleError(err)
    }
  }, [isMounted])

  const populateSignature = useCallback(async () => {
    try {
      setLoading(true)

      const res = await getSignature(signatureId)
      if (!isMounted()) return
      setOriginalSignature(res.signature)
      setSignature(res.signature)
      setSyncLabel(res.syncLabel)
      setSelectedTags(res.signature?.tags || [])
    } catch (err) {
      handleError(err)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [signatureId, isMounted])

  const populateUrls = useCallback(async () => {
    try {
      setUrlsLoading(true)

      const { entries, total } = await getSignatureURLs(signatureId, 1)
      if (!isMounted()) return
      setUrls(entries)
      setUrlsTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      if (!isMounted()) return
      setUrlsLoading(false)
    }
  }, [signatureId, isMounted])

  useEffect(() => {
    window.scrollTo(0, 0)
    populateSignature()
    populateSignatures()
    populateUrls()
    populateMappings()
  }, [populateSignature, populateSignatures, populateUrls, populateMappings])

  useEffect(() => {
    if (!signature) return

    if (signature.criteria.length !== 0 || signature.nonExclusiveCriteria.length !== 0) {
      setCriteriaOpen(true)
    }
  }, [signature])

  useEffect(() => {
    if (!signature) return

    if (signature.dependencies.length !== 0) {
      setDependenciesOpen(true)
    }
  }, [signature])

  useEffect(() => {
    if (!signature) return

    if (signature.appstores.length !== 0) {
      setAppStoreOpen(true)
    }
  }, [signature])

  useEffect(() => {
    if (!urls) return
    if (urls.length !== 0) {
      setUrlsOpen(true)
    }
  }, [urls])

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <>
      <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
      <Container>
        {signature && (
          <>
            <Grid container sx={{ mt: 3, mb: 5 }} spacing={1} justifyContent="space-between">
              <Grid item>
                <Grid container spacing={1}>
                  {user?.role === 0 && (
                    <Grid item>
                      <Button variant="outlined" color="secondary" disabled={loading} onClick={handleManualSync}>
                        Manual Sync with live publish
                      </Button>
                    </Grid>
                  )}
                  {user?.role === 0 && (
                    <Grid item>
                      <Button variant="outlined" color="secondary" disabled={loading} onClick={handleToggleViewOnly}>
                        {viewOnlyMode ? 'View in admin mode' : 'View in View-only mode'}
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <LoadingButton
                      variant="outlined"
                      color="primary"
                      loading={exportLoading}
                      onClick={handleExportSignature}
                    >
                      Export Signature
                    </LoadingButton>
                  </Grid>
                  {user?.role === 0 && (
                    <Grid item>
                      <Button variant="outlined" color="error" disabled={loading} onClick={handleToggleSync}>
                        Sync {syncLabel}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item>
                    <Typography variant="h4">{signature.name}</Typography>
                  </Grid>
                  <Grid item>
                    {signature.isCategory ? (
                      <Typography variant="h4" sx={{ color: '#3694E3' }} noWrap>
                        Theme
                      </Typography>
                    ) : signature.isSubCategory ? (
                      <Typography variant="h4" sx={{ color: '#189AB4' }} noWrap>
                        Category
                      </Typography>
                    ) : (
                      <Typography variant="h4" sx={{ color: '#6ECDD7' }} noWrap>
                        Signature
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Editor View */}
            {user?.role === 0 && !viewOnlyMode && (
              <Grid container sx={{ mt: 4 }} spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={3} direction="column">
                    <Grid item>
                      <Box onMouseEnter={() => setHovering(1)} onMouseLeave={() => setHovering(0)}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                          <Grid item flexGrow={1}>
                            <TextField variant="standard" label="ID" disabled fullWidth value={signature.id} />
                          </Grid>

                          {hovering === 1 && (
                            <Grid item>
                              <Tooltip title="Copy to clipboard">
                                <span>
                                  <IconButton disabled={!signature.id} onClick={() => handleCopyText(signature.id)}>
                                    <CopyIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box onMouseEnter={() => setHovering(2)} onMouseLeave={() => setHovering(0)}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                          <Grid item flexGrow={1}>
                            <TextField
                              variant="standard"
                              label="Display Name"
                              fullWidth
                              value={signature.name}
                              onChange={(e) => handleEditSignature('name', e)}
                            />
                          </Grid>
                          {hovering === 2 && (
                            <Grid item>
                              <Tooltip title="Copy to clipboard">
                                <span>
                                  <IconButton disabled={!signature.name} onClick={() => handleCopyText(signature.name)}>
                                    <CopyIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box onMouseEnter={() => setHovering(3)} onMouseLeave={() => setHovering(0)}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                          <Grid item flexGrow={1}>
                            <Autocomplete
                              id="detailsCategoryId"
                              options={handleGetSignatureCategoryIds()}
                              renderInput={(params) => (
                                <TextField {...params} variant="standard" label="Parent Category ID" />
                              )}
                              value={signature.category || null}
                              onChange={(e, newValue) => handleEditCategory(newValue)}
                            />
                          </Grid>
                          {hovering === 3 && (
                            <Grid item>
                              <Tooltip title="Go to category">
                                <span>
                                  <IconButton
                                    disabled={!signature.category || loading}
                                    onClick={() => handleClickSignature(signature?.category || '')}
                                  >
                                    <ExitToAppIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                          {hovering === 3 && (
                            <Grid item>
                              <Tooltip title="Copy to clipboard">
                                <span>
                                  <IconButton
                                    disabled={!signature.category}
                                    onClick={() => handleCopyText(signature?.category || '')}
                                  >
                                    <CopyIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box onMouseEnter={() => setHovering(4)} onMouseLeave={() => setHovering(0)}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                          <Grid item flexGrow={1}>
                            <TextField
                              variant="standard"
                              label="URL"
                              fullWidth
                              value={signature.url}
                              onChange={(e) => handleEditSignature('url', e)}
                            />
                          </Grid>
                          {hovering === 4 && (
                            <Grid item>
                              <Tooltip title="Copy to clipboard">
                                <span>
                                  <IconButton
                                    disabled={!signature.url}
                                    onClick={() => handleCopyText(signature?.url || '')}
                                  >
                                    <CopyIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item>
                      <TextField
                        label="Description"
                        fullWidth
                        multiline
                        value={signature.description}
                        onChange={(e) => handleEditSignature('description', e)}
                        helperText={`${signature.description.length} / 500`}
                      />
                    </Grid>
                    <Grid item>
                      <Paper variant="outlined">
                        <Grid container padding={2}>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={Boolean(signature.focus)}
                                  onChange={() => handleToggleEditSignature('focus')}
                                />
                              }
                              label="Focus"
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={Boolean(signature.reporting)}
                                  onChange={() => handleToggleEditSignature('reporting')}
                                />
                              }
                              label="Reporting"
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={Boolean(signature.hidden)}
                                  onChange={() => handleToggleEditSignature('hidden')}
                                />
                              }
                              label="Hidden"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Typography variant="overline">Tags</Typography>
                      <TagsEdit selectedTags={selectedTags} signatures={signatures} onChange={handleChangeTags} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={3} direction="column">
                    <Grid item>
                      <Box onMouseEnter={() => setHovering(5)} onMouseLeave={() => setHovering(0)}>
                        <Paper variant="outlined">
                          <Grid container alignItems="center" spacing={2} padding={1}>
                            <Grid item>
                              <Typography variant="overline">Favicon</Typography>
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2} padding={1}>
                            <Grid item>
                              <Button
                                disabled={!!faviconDataUrl || loading || user?.role !== 0}
                                variant="contained"
                                component="label"
                              >
                                Upload
                                <input
                                  type="file"
                                  hidden
                                  accept=".ico,.png,.jpg,.jpeg,.svg"
                                  ref={fileUpload}
                                  onChange={handleChangeUpload}
                                />
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                onClick={handleFetchFavicon}
                                disabled={loading || !originalSignature?.url}
                              >
                                Fetch
                              </Button>
                            </Grid>
                            <Grid item>
                              <IconButton
                                disabled={!faviconDataUrl || user?.role !== 0}
                                sx={{ mx: 1 }}
                                onClick={handleClearFileUpload}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <LazyImage
                                src={
                                  faviconDataUrl
                                    ? `${signature?.faviconUrl || ''}`
                                    : `${signature?.faviconUrl || ''}?${new Date().getTime().toString(16)}`
                                }
                                size={24}
                              />
                            </Grid>
                            {hovering === 5 && (
                              <Grid item sx={{ pr: 2 }}>
                                <Tooltip title="Copy to clipboard">
                                  <span>
                                    <IconButton onClick={() => handleCopyText(signature?.faviconUrl || '')}>
                                      <CopyIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        </Paper>
                      </Box>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(signature.enabled)}
                            onChange={() => handleToggleEditSignature('enabled')}
                          />
                        }
                        label="Enabled"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(signature.noise)}
                            onChange={() => handleToggleEditSignature('noise')}
                          />
                        }
                        label="Noise"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={<Switch checked={!!autoCategory} onChange={handleSetAutoCategory} />}
                        label="Set Category Type Automatically"
                      />
                    </Grid>
                    <Grid item justifySelf="end">
                      <Collapse in={!autoCategory}>
                        <ToggleButtonGroup
                          value={getCategoryType()}
                          exclusive
                          onChange={handleChangeCategoryType}
                          aria-label="category type"
                        >
                          <ToggleButton value="category" aria-label="category" disabled={autoCategory}>
                            Theme
                          </ToggleButton>
                          <ToggleButton value="subCategory" aria-label="sub category" disabled={autoCategory}>
                            Category
                          </ToggleButton>
                          <ToggleButton value="signature" aria-label="signature" disabled={autoCategory}>
                            Signature
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Collapse>
                    </Grid>

                    <Grid item justifySelf="end">
                      <Grid container spacing={2} direction="column">
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={loading || !isModified()}
                            onClick={handleSaveSignature}
                          >
                            Save Signature
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="error"
                            disabled={originalSignature?.enabled}
                            startIcon={<DeleteIcon />}
                            onClick={handleOpenDeleteSignature}
                          >
                            Delete Signature
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* Detailed View */}
            {(user?.role !== 0 || viewOnlyMode) && (
              <Grid container spacing={2} sx={{ mt: 4 }}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box onMouseEnter={() => setHovering(100)} onMouseLeave={() => setHovering(0)}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                          <Grid item flexGrow={1}>
                            <Typography variant="overline">Display Name</Typography>
                            <Typography variant="h6">{signature.name}</Typography>
                          </Grid>
                          {hovering === 100 && (
                            <Grid item sx={{ pr: 2 }}>
                              <Tooltip title="Copy to clipboard">
                                <span>
                                  <IconButton onClick={() => handleCopyText(signature.name)}>
                                    <CopyIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box onMouseEnter={() => setHovering(101)} onMouseLeave={() => setHovering(0)}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                          <Grid item flexGrow={1}>
                            <Typography variant="overline">Category Type</Typography>
                            <Typography variant="h6">
                              {signature.isSubCategory ? 'Category' : signature.isCategory ? 'Theme' : 'Signature'}
                            </Typography>
                          </Grid>
                          {hovering === 101 && (
                            <Grid item sx={{ pr: 2 }}>
                              <Tooltip title="Copy to clipboard">
                                <span>
                                  <IconButton
                                    onClick={() =>
                                      handleCopyText(
                                        signature.isSubCategory
                                          ? 'Category'
                                          : signature.isCategory
                                          ? 'Theme'
                                          : 'Signature'
                                      )
                                    }
                                  >
                                    <CopyIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box onMouseEnter={() => setHovering(102)} onMouseLeave={() => setHovering(0)}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                          <Grid item flexGrow={1}>
                            <Typography variant="overline">Signature ID</Typography>
                            <Typography variant="h6">{signature.id}</Typography>
                          </Grid>
                          {hovering === 102 && (
                            <Grid item sx={{ pr: 2 }}>
                              <Tooltip title="Copy to clipboard">
                                <span>
                                  <IconButton onClick={() => handleCopyText(signature.id)}>
                                    <CopyIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography variant="overline">Enabled</Typography>
                      <br />
                      {signature.enabled ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box onMouseEnter={() => setHovering(103)} onMouseLeave={() => setHovering(0)}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                          <Grid item flexGrow={1}>
                            <Typography variant="overline">Parent Category ID</Typography>

                            {signature.category ? (
                              <>
                                <br />
                                <Link
                                  variant="h6"
                                  onClick={(e) => handleClickSignature(signature?.category || '', e)}
                                  underline="none"
                                  href={`/signature/${signature.category}`}
                                >
                                  {signature.category}
                                </Link>
                              </>
                            ) : (
                              <Typography variant="h6">N / A</Typography>
                            )}
                          </Grid>
                          {hovering === 103 && (
                            <Grid item sx={{ pr: 2 }}>
                              <Tooltip title="Copy to clipboard">
                                <span>
                                  <IconButton onClick={() => handleCopyText(signature?.category || '')}>
                                    <CopyIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography variant="overline">Noise</Typography>
                      <br />
                      {signature.noise ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box onMouseEnter={() => setHovering(104)} onMouseLeave={() => setHovering(0)}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                          <Grid item flexGrow={1}>
                            <Typography variant="overline">URL</Typography>

                            {signature.url ? (
                              <>
                                <br />
                                <Link
                                  variant="h6"
                                  underline="none"
                                  href={'https://' + signature.url}
                                  rel="noopener"
                                  target="_blank"
                                >
                                  {signature.url}
                                </Link>
                              </>
                            ) : (
                              <Typography variant="h6">N / A</Typography>
                            )}
                          </Grid>
                          {hovering === 104 && (
                            <Grid item sx={{ pr: 2 }}>
                              <Tooltip title="Copy to clipboard">
                                <span>
                                  <IconButton onClick={() => handleCopyText(signature?.url || '')}>
                                    <CopyIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <Box onMouseEnter={() => setHovering(105)} onMouseLeave={() => setHovering(0)}>
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                          <Grid item flexGrow={1}>
                            <Typography variant="overline">Favicon</Typography>
                            <LazyImage src={signature.faviconUrl + '?' + new Date().getTime().toString(16)} />
                          </Grid>
                          {hovering === 105 && (
                            <Grid item sx={{ pr: 2 }}>
                              <Tooltip title="Copy to clipboard">
                                <span>
                                  <IconButton onClick={() => handleCopyText(signature?.faviconUrl || '')}>
                                    <CopyIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography variant="overline">Classwize Focus</Typography>
                      <br />
                      {signature.focus ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography variant="overline">Reporting</Typography>
                      <br />
                      {signature.reporting ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography variant="overline">Hidden</Typography>
                      <br />
                      {signature.hidden ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography variant="overline">Urls</Typography>
                      <Typography variant="h6">{urls.length}</Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="overline">Tags</Typography>
                      <Typography variant="h6">{signature.tags.join(', ') || 'N / A'}</Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="overline">Description</Typography>
                      <Typography variant="h6">{signature.description || 'N / A'}</Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </Grid>
              </Grid>
            )}
            <Grid container direction="column" spacing={2} sx={{ mb: 6, mt: 2 }}>
              <Grid item>
                <Accordion variant="outlined" expanded={criteriaOpen}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={handleCriteriaAccordionClick}
                  >
                    <Typography sx={{ mx: 2 }}>Criteria</Typography>
                    <Typography variant="caption">
                      {signature?.criteria?.length !== 1
                        ? ` ${signature?.criteria?.length} Exclusives`
                        : ` 1 Exclusive`}
                    </Typography>
                    <Typography variant="caption" sx={{ ml: 2 }}>
                      {signature?.nonExclusiveCriteria?.length !== 1
                        ? ` ${signature?.nonExclusiveCriteria?.length} Non Exclusives`
                        : ` 1 Non Exclusive`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {user?.role === 0 && <Typography sx={{ mx: 2 }}>Create New Criteria</Typography>}
                    {user?.role === 0 && (
                      <Grid container>
                        <Grid container spacing={1} padding={1} justifyContent="left">
                          <Grid item xs={6} alignContent="left">
                            <Accordion variant="outlined" expanded={exampleOpen}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                                onClick={handleExampleExpanded}
                              >
                                Example format
                              </AccordionSummary>
                              <AccordionDetails>
                                <JSONInput
                                  locale={locale}
                                  viewOnly
                                  placeholder={[
                                    {
                                      negate: false,
                                      type: 'stateful.criteria',
                                      conditions: {
                                        packet_limit: 5,
                                        pieces: [
                                          {
                                            id: 'DNS',
                                            criteria: [
                                              {
                                                negate: false,
                                                type: 'destination.transport.port',
                                                conditions: [53],
                                              },
                                              {
                                                negate: false,
                                                type: 'bytes',
                                                conditions: {
                                                  bytes: [0, 0, 0],
                                                  offset: 1,
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    },
                                  ]}
                                  confirmGood={false}
                                  theme={darkMode ? 'dark_vscode_tribute' : 'light_mitsuketa_tribute'}
                                  height="400"
                                  width="auto"
                                />
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} padding={1} justifyContent="center">
                          <Grid item xs={12} sm={6}>
                            <Paper elevation={0}>
                              {criteriaOpen && (
                                <JSONInput
                                  locale={locale}
                                  placeholder={criteria}
                                  onChange={handleEditCriteria}
                                  confirmGood={false}
                                  waitAfterKeyPress={2000}
                                  theme={darkMode ? 'dark_vscode_tribute' : 'light_mitsuketa_tribute'}
                                  height="auto"
                                  width="auto"
                                />
                              )}
                            </Paper>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Grid container direction="column" justifyContent="center" spacing={2}>
                              <Grid item>
                                <Collapse in={!!criteriaError}>
                                  <Box>
                                    <Grid container justifyContent="center">
                                      <Grid item xs={12}>
                                        <Alert severity="error" variant="standard" sx={{ mb: 2 }}>
                                          {criteriaError || ' '}
                                        </Alert>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Collapse>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  fullWidth
                                  disabled={loading || !!criteriaError}
                                  onClick={handleCriteriaAdd}
                                >
                                  Add Criteria
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  fullWidth
                                  disabled={loading || !!criteriaError}
                                  onClick={handleNECriteriaAdd}
                                >
                                  Add Non-Exclusive-Criteria
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container padding={1}>
                      <Grid item>
                        <Typography>Criteria</Typography>
                        <Typography variant="caption">
                          {signature.criteria?.length !== 1 ? ` ${signature.criteria?.length} Entries` : ` 1 Entry`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} padding={1}>
                      {signature.criteria.map((criteria, index) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={`criteria-${index}`}>
                          <Paper elevation={3}>
                            {user?.role === 0 && (
                              <Grid container justifyContent="end" padding={1} spacing={1}>
                                <Grid item>
                                  <Fab
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      window.navigator.clipboard.writeText(JSON.stringify(criteria?.criteria, null, 2))
                                    }
                                  >
                                    <CopyIcon />
                                  </Fab>
                                </Grid>
                                <Grid item>
                                  <Fab
                                    size="small"
                                    color="error"
                                    onClick={() => handleOpenCriteriaDelete(criteria)}
                                    disabled={loading}
                                  >
                                    <DeleteIcon />
                                  </Fab>
                                </Grid>
                              </Grid>
                            )}

                            {typeof criteria?.criteria === 'object' && (
                              <JSONInput
                                locale={locale}
                                viewOnly
                                placeholder={criteria?.criteria || []}
                                confirmGood={false}
                                theme={darkMode ? 'dark_vscode_tribute' : 'light_mitsuketa_tribute'}
                                height="400"
                                width="auto"
                              />
                            )}
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid container padding={1}>
                      <Grid item>
                        <Typography>Non Exclusive Criteria</Typography>
                        <Typography variant="caption">
                          {signature.nonExclusiveCriteria?.length !== 1
                            ? ` ${signature.nonExclusiveCriteria?.length} Entries`
                            : ` 1 Entry`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} padding={1}>
                      {signature.nonExclusiveCriteria.map((nonExclusiveCriteria, index) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={`nonExclusiveCriteria-${index}`}>
                          <Paper elevation={3}>
                            {user?.role === 0 && (
                              <Grid container justifyContent="end" padding={1} spacing={1}>
                                <Grid item>
                                  <Fab
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      window.navigator.clipboard.writeText(
                                        JSON.stringify(nonExclusiveCriteria?.criteria, null, 2)
                                      )
                                    }
                                  >
                                    <CopyIcon />
                                  </Fab>
                                </Grid>
                                <Grid item>
                                  <Fab
                                    size="small"
                                    color="error"
                                    onClick={() => handleOpenNECriteriaDelete(nonExclusiveCriteria)}
                                    disabled={loading}
                                  >
                                    <DeleteIcon />
                                  </Fab>
                                </Grid>
                              </Grid>
                            )}

                            {typeof nonExclusiveCriteria?.criteria === 'object' && (
                              <JSONInput
                                locale={locale}
                                viewOnly
                                placeholder={nonExclusiveCriteria?.criteria || []}
                                confirmGood={false}
                                theme={darkMode ? 'dark_vscode_tribute' : 'light_mitsuketa_tribute'}
                                height="400"
                                width="auto"
                              />
                            )}
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item>
                <Accordion variant="outlined" expanded={urlsOpen}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    onClick={handleUrlAccordionClick}
                  >
                    <Typography sx={{ mx: 2 }}>Signature URLs</Typography>
                    <Typography variant="caption">{urlsTotal !== 1 ? ` ${urlsTotal} Entries` : ` 1 Entry`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <URLTable
                      urls={urls}
                      total={urlsTotal}
                      error={urlError}
                      loading={urlsLoading}
                      admin={user?.role === 0}
                      onDelete={handleDeleteURL}
                      onReclassify={handleOpenReclassify}
                      onPromote={handleClickPromote}
                      onAdd={handleToggleAddURL}
                      onAddSecondary={handleToggleAddSecondary}
                      onLoadMore={handleLoadMoreURLs}
                      isTheme={signature.isCategory}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item>
                <Accordion variant="outlined" expanded={dependenciesOpen}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    onClick={handleDependencyAccordionClick}
                  >
                    <Typography sx={{ mx: 2 }}>Signature Dependencies</Typography>

                    <Typography variant="caption">
                      {signature?.dependencies?.length !== 1
                        ? ` ${signature?.dependencies?.length} Entries`
                        : ` 1 Entry`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DependencyTable
                      dependencies={signature.dependencies}
                      loading={loading}
                      admin={user?.role === 0}
                      onClick={handleClickSignature}
                      onDelete={handleDeleteDependency}
                      onAdd={handleToggleAddDependency}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item>
                <Accordion variant="outlined" expanded={appStoreOpen}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    onClick={handleAppAccordionClick}
                  >
                    <Typography sx={{ mx: 2 }}>AppStores</Typography>
                    <Typography variant="caption">
                      {signature?.appstores?.length !== 1 ? ` ${signature?.appstores?.length} Entries` : ` 1 Entry`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AppTable
                      apps={signature.appstores}
                      loading={loading}
                      admin={user?.role === 0}
                      onDelete={handleOpenDeleteApplication}
                      onAdd={handleToggleAddApp}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item>
                <Accordion variant="outlined" expanded={mappingsOpen}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    onClick={handleMappingsAccordionClick}
                  >
                    <Typography sx={{ mx: 2 }}>Signature Mappings</Typography>

                    <Typography variant="caption">
                      {mappings?.length !== 1 ? ` ${mappings?.length} Entries` : ` 1 Entry`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MappingsTable
                      admin={user?.role === 0}
                      mappings={mappings}
                      loading={mappingsLoading}
                      defaultRows={10}
                      hideSignatureHeading
                      onDelete={handleOpenDeleteMapping}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </>
        )}
        <ReclassifyDialog
          open={reclassifyOpen}
          signature={signature?.id || ''}
          url={activeUrl}
          signatures={signatures.filter((sig) => sig.isCategory === false)}
          onClose={handleCloseReclassify}
          afterReclassify={handleAfterReclassify}
        />
        <AddDependencyDialog
          open={addDependencyOpen}
          error={dependencyError}
          loading={loading}
          signatures={signatures}
          onClickAdd={handleAddDependency}
          onClose={handleToggleAddDependency}
        />
        <RecatDialog
          open={addUrlOpen}
          signatureId={signature?.id || ''}
          onClose={handleToggleAddURL}
          onRefresh={populateUrls}
          secondaryURLs={secondaryURLs}
        />
        <AddApplicationDialog
          open={addAppStoreOpen}
          error={appError}
          loading={loading}
          onClickAdd={handleAddApplication}
          onClose={handleToggleAddApp}
        />
        <SyncDialog
          open={syncOpen}
          signatureId={signature?.id || ''}
          syncLabel={syncLabel}
          onClose={handleToggleSync}
        />
        <DeleteConfirmDialog
          loading={mappingsLoading}
          open={deleteMappingOpen}
          name={`${activeMapping?.signatureId} mapping`}
          onClose={handleCloseDeleteMapping}
          onDelete={handleDeleteMapping}
        />
        <DeleteConfirmDialog
          open={deleteApplicationOpen}
          name={activeControlApp !== '' ? `the linking for ${activeControlApp}` : `${activeAppID}`}
          loading={loading}
          onClose={handleCloseDeleteApplication}
          onDelete={handleDeleteApp}
        />
        <DeleteConfirmDialog
          open={deleteSignatureOpen}
          name={signature?.name || ''}
          loading={loading}
          onClose={handleCloseDeleteSignature}
          onDelete={handleDeleteSignature}
        />
        <DeleteConfirmDialog
          open={!!deleteCriteria}
          name={'Criteria'}
          loading={loading}
          onClose={handleCloseCriteriaDelete}
          onDelete={handleDeleteCriteria}
        />
        <DeleteConfirmDialog
          open={!!deleteNECriteria}
          name={'Non Exclusive Criteria'}
          loading={loading}
          onClose={handleCloseNECriteriaDelete}
          onDelete={handleDeleteNECriteria}
        />
        {activeUrl && (
          <ConfirmDialog
            title="Confirm Promotion"
            text={`Are you sure you want to promote the url ${activeUrl} associated with ${signatureId} to primary?`}
            open={promoteOpen}
            color="error"
            onConfirm={handleConfirmPromote}
            onClose={handleClosePromote}
          />
        )}
      </Container>
    </>
  )
}

export default SignatureDetails
