import React, { useState, useEffect, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import TableSortLabel from '@mui/material/TableSortLabel'
import RefreshIcon from '@mui/icons-material/Refresh'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import SearchBar from './SearchBar.tsx'
import Fab from '@mui/material/Fab'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import useIsMounted from 'react-is-mounted-hook'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import AltRouteIcon from '@mui/icons-material/AltRoute'

import DeleteConfirmDialog from './DeleteConfirmDialog.tsx'
import { Url } from '../util/Api.tsx'

const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

interface URLTableProps {
  urls?: Url[]
  error?: string
  total?: number
  loading?: boolean
  admin?: boolean
  onDelete?: (activeUrl: string) => void
  onReclassify?: (activeUrl: string) => void
  onPromote?: (activeUrl: string) => void
  onAdd?: () => void
  onAddSecondary?: () => void
  onLoadMore?: (e: React.MouseEvent<HTMLButtonElement>) => void
  isTheme?: boolean
}

const URLTable = ({
  urls = [],
  error = '',
  total = 0,
  loading = false,
  admin = false,
  onDelete = () => {},
  onReclassify = () => {},
  onPromote = () => {},
  onAdd = () => {},
  onAddSecondary = () => {},
  onLoadMore = () => {},
  isTheme = false,
}: URLTableProps) => {
  const isMounted = useIsMounted()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [orderBy, setOrderBy] = useState<keyof Url>('website')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [shownUrls, setShownUrls] = useState<Url[]>([])
  const [search, setSearch] = useState('')
  const [clickedUrl, setClickedUrl] = useState<string | null>(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [activeUrl, setActiveUrl] = useState<string | null>(null)

  const handleCloseDelete = () => {
    setDeleteOpen(false)
  }

  const getUrls = useCallback(() => {
    const sortedUrls = shownUrls.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentUrls: (Url | null)[] = sortedUrls.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentUrls.length
    if (currentUrls.length === rowsPerPage) return currentUrls
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentUrls.push(null)
    }
    return currentUrls
  }, [shownUrls, page, rowsPerPage, order, orderBy])

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setShownUrls(urls.filter((url) => url.website.includes(e.target.value)))
    setPage(0)
  }

  const handleClickAdd = () => {
    onAdd()
  }

  const handleClickAddSecondary = () => {
    onAddSecondary()
  }

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleCopyText = async (url?: string | null) => {
    try {
      if (!url) return
      navigator.clipboard.writeText(url)
      setClickedUrl(url)
      await timeout(2000)
      if (!isMounted()) return
      setClickedUrl(null)
    } catch (err) {
      console.error(err)
    }
  }

  const handleDelete = () => {
    if (!activeUrl) return
    onDelete(activeUrl)
    setDeleteOpen(false)
  }

  const handleOpenDelete = (e: React.MouseEvent<HTMLButtonElement>, url: string) => {
    e.preventDefault()
    e.stopPropagation()
    setActiveUrl(url)
    setDeleteOpen(true)
  }

  const handleClickReclassify = (e: React.MouseEvent<HTMLButtonElement>, url: string) => {
    e.preventDefault()
    e.stopPropagation()
    onReclassify(url)
  }

  const handleClickPromote = (e: React.MouseEvent<HTMLButtonElement>, url: string) => {
    e.preventDefault()
    e.stopPropagation()
    onPromote(url)
  }

  const handleClickLoadMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    onLoadMore(e)
  }

  const createSortHandler = (id: keyof Url) => (e: React.MouseEvent<HTMLSpanElement>) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
    setShownUrls(urls)
    setSearch('')
  }, [urls])

  return (
    <Paper variant="outlined">
      <Grid container justifyContent="center" alignItems="center" spacing={2} padding={2}>
        {admin && isTheme !== true && (
          <Grid item>
            <Fab size="small" color="primary" onClick={handleClickAdd}>
              <MenuOpenIcon />
            </Fab>
          </Grid>
        )}
        <Grid item>
          <SearchBar dark value={search} onChange={handleChangeSearch} />
        </Grid>
        {admin && (
          <Grid item>
            <Fab size="small" color="secondary" onClick={handleClickAddSecondary}>
              <AltRouteIcon />
            </Fab>
          </Grid>
        )}
      </Grid>
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Collapse in={Boolean(error)}>
          <Box>
            <Grid container padding={2} justifyContent="center">
              <Grid item xs={12}>
                <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                  {error || ' '}
                </Alert>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'website'}
                  direction={orderBy === 'website' ? order : 'asc'}
                  onClick={createSortHandler('website')}
                >
                  Entry
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'entryType'}
                  direction={orderBy === 'entryType' ? order : 'asc'}
                  onClick={createSortHandler('entryType')}
                >
                  Entry Type
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'primarySig'}
                  direction={orderBy === 'primarySig' ? order : 'asc'}
                  onClick={createSortHandler('primarySig')}
                >
                  Primary Signature
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'imported'}
                  direction={orderBy === 'imported' ? order : 'asc'}
                  onClick={createSortHandler('imported')}
                >
                  Date Added
                </TableSortLabel>
              </TableCell>
              {admin && <TableCell align="right">Reclassify / Delete</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {getUrls().map((row, index) => (
              <Tooltip
                open={clickedUrl === (row?.website || '')}
                placement="top"
                title="Copied!"
                key={`${row?.website || ''}-${index}`}
              >
                <TableRow
                  hover
                  key={`${row?.website || ''}-${index}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: !row?.website ? undefined : 'pointer',
                    height: 40,
                    opacity: !row?.website ? 0 : 1,
                  }}
                  onClick={() => handleCopyText(row?.website)}
                >
                  <TableCell component="th" scope="row" align="left">
                    {row?.website && (
                      <Typography variant="body2" noWrap>
                        <Link underline="none" href={'https://' + row.website} rel="noopener" target="_blank">
                          {row.website}
                        </Link>
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" noWrap>
                      {row?.entryType || ''}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" noWrap>
                      {row?.primarySig ? 'True' : 'False'}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" noWrap>
                      {row?.imported
                        ? Intl.DateTimeFormat().resolvedOptions().timeZone.substring(0, 7) === 'America'
                          ? Intl.DateTimeFormat('en-US', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                            }).format(new Date(row?.imported))
                          : Intl.DateTimeFormat('en-GB', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                            }).format(new Date(row?.imported))
                        : 'No Recorded Date'}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {admin && row?.website && (
                      <>
                        {row.primarySig === false && (
                          <Tooltip title="Promote to Primary">
                            <IconButton onClick={(e) => handleClickPromote(e, row.website)}>
                              <ArrowUpwardIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <IconButton
                          size="small"
                          disabled={loading || !row.website}
                          onClick={(e) => handleClickReclassify(e, row.website)}
                        >
                          <RefreshIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          disabled={loading || !row.website}
                          onClick={(e) => handleOpenDelete(e, row.website)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              </Tooltip>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justifyContent="end" padding={1}>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            disabled={urls.length === total || loading}
            onClick={handleClickLoadMore}
          >
            Load more
          </Button>
        </Grid>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={shownUrls.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DeleteConfirmDialog
        loading={loading}
        open={deleteOpen}
        name={`"${activeUrl}" url`}
        onClose={handleCloseDelete}
        onDelete={handleDelete}
      />
    </Paper>
  )
}

export default URLTable
