import React, { useState, useEffect } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import LoadingButton from '@mui/lab/LoadingButton'

import CloseIcon from '@mui/icons-material/Close'

import { useTheme } from '@mui/material/styles'

import handleError from '../util/Error.tsx'
import { createSignature, Signature } from '../util/Api.tsx'
import SignatureSelect from './SignatureSelect.tsx'

interface SignatureCreateProps {
  open?: boolean
  onClose?: () => void
  onSave?: (id: string) => void
  signatures: Signature[]
  defaultCategory?: Signature | null
}

const SignatureCreate = ({
  open = false,
  onClose = () => {},
  onSave = () => {},
  signatures = [],
  defaultCategory,
}: SignatureCreateProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('sphirewall.application.')
  const [name, setName] = useState('')
  const [category, setCategory] = useState<Signature | null>(null)
  const isCategory = false
  const isSubCategory = false
  const autoCategory = true

  const handleChangeCategory = (e: React.SyntheticEvent, newCategory: Signature | null) => {
    setCategory(newCategory)
  }

  const handleSave = async () => {
    try {
      setLoading(true)
      await createSignature(id, {
        category: category?.id || null,
        name,
        isSubCategory,
        isCategory,
        autoCategory,
      })
      if (!isMounted()) return

      onClose()
      onSave(id)
      setError('')
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  useEffect(() => {
    setError('')
  }, [open])

  useEffect(() => {
    if (!defaultCategory) return
    setCategory(defaultCategory)
  }, [defaultCategory])

  const categories = signatures.filter((s) => s.isCategory || s.isSubCategory)

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Create a new signature
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container padding={1} justifyContent="center">
            <Grid item>
              {open && (
                <Collapse in={Boolean(error)}>
                  <Alert severity="error">{error || 'Error'}</Alert>
                </Collapse>
              )}
            </Grid>
          </Grid>
          <Grid container padding={3} spacing={2}>
            <Grid item xs={12}>
              <TextField label="Signature ID" fullWidth value={id} onChange={(e) => setId(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Display Name" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <SignatureSelect
                signatures={categories}
                defaultSignature={defaultCategory}
                label="Select a category..."
                onChange={handleChangeCategory}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={loading}
                    onClick={handleSave}
                  >
                    Create Signature
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default SignatureCreate
