import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Fab,
  Grid,
  LinearProgress,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  ImageClassifierCategory,
  ImageClassifierEntries,
  ImageClassifierReloadTerms,
  ImageClassifierResponse,
  Signature,
  addImageClassifierCategory,
  addImageClassifierEntries,
  deleteImageClassifierCategory,
  deleteImageClassifierEntries,
  editImageClassifierCategory,
  editImageClassifierEntries,
  getImageClassifierCategory,
  getImageClassifierEntries,
  getSignatures,
  queryAppindexClip,
  queryAppindexClipImage,
} from '../util/Api.tsx'
import { getBase64 } from '../util/Func.tsx'
import useStore from '../util/Store.tsx'
import useIsMounted from 'react-is-mounted-hook'
import SearchBar from '../components/SearchBar.tsx'
import ImageClassifierTable from '../components/ImageClassifierTable.tsx'
import AddIcon from '@mui/icons-material/Add'
import ImageClassiferEntryTable from '../components/ImageClassifierEntryTable.tsx'
import AddClassiderTerm from '../components/AddClassifierTerm.tsx'
import AddClassifierCategory from '../components/AddClassifierCategory.tsx'
import DeleteConfirmDialog from '../components/DeleteConfirmDialog.tsx'

const ImageClassifier = () => {
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [entry, setEntry] = useState<any>('')
  const [result, setResult] = useState<ImageClassifierResponse | null>(null)
  const [statsExpanded, setStatsExpanded] = useState<boolean>(false)
  const [user] = useStore('user')
  const isMounted = useIsMounted()
  const fileUpload = useRef(null)
  const [selectedCategory, setSelectedCategory] = useState<ImageClassifierCategory | null>(null)
  const [selectedEntry, setSelectedEntry] = useState<ImageClassifierEntries | null>(null)
  const [imageClassifierEntries, setImageClassifierEntries] = useState<ImageClassifierEntries[]>([])
  const [imageClassifierCategories, setImageClassifierCategories] = useState<ImageClassifierCategory[]>([])
  const [filteredEntries, setFilteredEntries] = useState<ImageClassifierEntries[]>([])
  const [filteredCategories, setFilteredCategories] = useState<ImageClassifierCategory[]>([])
  const [signatures, setSignatures] = useState<Signature[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchCategory, setSearchCategory] = useState<string>('')
  const [darkMode] = useStore('darkMode')
  const [selector, setSelector] = useState(0)
  const [openEntryCreate, setOpenEntryCreate] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)
  const [openCategoryCreate, setOpenCategoryCreate] = useState<boolean>(false)
  const [openEntryDelete, setOpenEntryDelete] = useState<boolean>(false)
  const [openCategoryDelete, setOpenCategoryDelete] = useState<boolean>(false)
  const [queryEntry, setQueryEntry] = useState<string>('')

  const handleChangeEntry = (value: string) => {
    setEntry(value)
  }

  const handleChangePhrase = (value: string) => {
    setQueryEntry(value)
  }

  const handleClearPhrase = () => {
    setQueryEntry('')
  }

  const handleQueryOpenClip = async () => {
    try {
      setError('')
      setResult(null)
      setLoading(true)
      const data = await queryAppindexClip(entry)
      setResult(data.data)
      setStatsExpanded(true)
    } catch (e) {
      setError('Unable to resolve URL or the Server threw an error, please confirm the URL and try again.')
      setResult(null)
    }
    setLoading(false)
  }

  const handleOpenClipTerms = async () => {
    try {
      setError('')
      if (!result) return
      if (!queryEntry) {
        setError('Please enter a term before proceeding')
        return
      }
      setResult(null)
      setLoading(true)
      const data = await queryAppindexClipImage(result.encoded_image, queryEntry)
      setResult(data.data)
      setStatsExpanded(true)
    } catch (e) {
      setError('Unable to resolve URL or the Server threw an error, please confirm the URL and try again.')
      setResult(null)
    }
    setLoading(false)
  }

  const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files) return
      if (e.target.files.length === 0) {
        return
      }
      setResult(null)
      setError('')
      setLoading(true)
      const dataUrl = await getBase64(e.target.files[0])
      const data = await queryAppindexClipImage(dataUrl.split(',')[1], '')
      if (!isMounted()) return
      setResult(data.data)
      setStatsExpanded(true)
    } catch (e) {
      setError(
        'Unable to submit image for analysis, please ensure you image is not more than 10MBs and is of the following format: jpg, png or jpeg'
      )
      setResult(null)
    }
    setLoading(false)
  }

  const handleReloadTerms = async () => {
    try {
      setLoading(true)
      setError('')
      await ImageClassifierReloadTerms()
      if (!isMounted()) return
    } catch (e) {
      if (!isMounted()) return
      setError('Error Reloading terms, please try again later')
      console.error(e)
    }
    setLoading(false)
  }

  const handleCreateCategoryOpen = () => {
    setEditing(false)
    setOpenCategoryCreate(true)
    setSelectedCategory(null)
  }

  const handleCreateCategoryClose = () => {
    setOpenCategoryCreate(false)
  }

  const handleCreateEntryOpen = () => {
    setEditing(false)
    setOpenEntryCreate(true)
  }

  const handleCreateEntryClose = () => {
    setOpenEntryCreate(false)
    setSelectedEntry(null)
  }

  const handleSelector = (event: React.SyntheticEvent, newValue: number) => {
    setSelector(newValue)
  }

  useEffect(() => {
    if (selector === 1) {
      setSelectedCategory(null)
      setSelectedEntry(null)
    }
    setError('')
  }, [selector])

  const handleClickCategory = (category: ImageClassifierCategory) => {
    setSelectedCategory(category)
    setFilteredEntries(imageClassifierEntries.filter((entry) => entry.category_id === category.id))
    setSearchTerm('')
  }

  const handleEditCategoryOpen = (category: ImageClassifierCategory) => {
    setEditing(true)
    setSelectedCategory(category)
    setOpenCategoryCreate(true)
  }

  const handleDeleteCategoryOpen = (category: ImageClassifierCategory) => {
    setError('')
    if (imageClassifierEntries.filter((entry) => entry.category_id === category.id).length > 0) {
      setError('Unable to delete category, please delete all associated terms first')
      return
    }
    setSelectedCategory(category)
    setOpenCategoryDelete(true)
  }

  const handleChangeSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    setFilteredEntries(
      imageClassifierEntries.filter((entry) => {
        return (
          (entry.term.toLowerCase().includes(e.target.value.toLowerCase()) &&
            selectedCategory &&
            entry.category_id === selectedCategory.id) ||
          (entry.notes?.toLowerCase().includes(e.target.value.toLowerCase()) &&
            selectedCategory &&
            entry.category_id === selectedCategory.id)
        )
      })
    )
  }

  const handleChangeSearchCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCategory(e.target.value)
    setFilteredCategories(
      imageClassifierCategories.filter((category) => {
        return (
          category.label.toLowerCase().includes(e.target.value.toLowerCase()) ||
          category.signature_id.toLowerCase().includes(e.target.value.toLowerCase()) ||
          category.threshold.toString().includes(e.target.value.toLowerCase())
        )
      })
    )
  }

  const handleAddTerm = async (term: string, notes: string) => {
    if (!term || !selectedCategory?.id) return
    try {
      setLoading(true)
      setError('')
      await addImageClassifierEntries(term, selectedCategory.id, notes?.toString(), user.email)
      if (!isMounted()) return
      setOpenEntryCreate(false)
      populateImageClassifierEntries()
    } catch (e) {
      if (!isMounted()) return
      setError('Error Adding Term, please try again later')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handleAddCategory = async (label: string, signature_id: string | undefined, threshold: number) => {
    if (!label) return
    if (!signature_id) {
      setError('Please select a signature')
      return
    }
    if (threshold === 0) {
      setError('Please set a threshold')
      return
    }
    try {
      setLoading(true)
      setError('')
      await addImageClassifierCategory(label, signature_id, threshold)
      if (!isMounted()) return
      setOpenCategoryCreate(false)
      populateImageClassifierCategory()
    } catch (e) {
      if (!isMounted()) return
      setError('Error Adding Category, please try again later')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handleEditCategory = async (
    classifierCategory: ImageClassifierCategory,
    label: string,
    signature_id: string | undefined,
    threshold: number
  ) => {
    if (!label || !threshold || !classifierCategory) return
    if (!signature_id) {
      setError('Please select a signature')
      return
    }
    try {
      setLoading(true)
      setError('')
      await editImageClassifierCategory(
        classifierCategory.id,
        label,
        threshold,
        classifierCategory.signature_id,
        signature_id
      )
      if (!isMounted()) return
      setOpenCategoryCreate(false)
      populateImageClassifierCategory()
    } catch (e) {
      if (!isMounted()) return
      setError('Error Editing Category, please try again later')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handleEditTerm = async (classifierEntry: ImageClassifierEntries, term: string, notes: string) => {
    if (!term || !classifierEntry) return
    try {
      setLoading(true)
      setError('')
      await editImageClassifierEntries(
        term,
        classifierEntry.category_id,
        notes,
        user.email,
        classifierEntry.term,
        classifierEntry.notes
      )

      if (!isMounted()) return
      setOpenEntryCreate(false)
      populateImageClassifierEntries()
    } catch (e) {
      if (!isMounted()) return
      setError('Error Editing Term, please try again later')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handleEditTermOpen = (entry: ImageClassifierEntries) => {
    setEditing(true)
    setSelectedEntry(entry)
    setOpenEntryCreate(true)
  }

  const handleDeleteTermOpen = (entry: ImageClassifierEntries) => {
    setSelectedEntry(entry)
    setOpenEntryDelete(true)
  }

  const handleDeleteTerm = async () => {
    if (!selectedEntry) return
    try {
      setLoading(true)
      setError('')
      await deleteImageClassifierEntries(selectedEntry.term, selectedEntry.category_id)
      if (!isMounted()) return
      setOpenEntryDelete(false)
      populateImageClassifierEntries()
    } catch (e) {
      if (!isMounted()) return
      setError('Error Deleting Term, please try again later')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteCategory = async () => {
    if (!selectedCategory) return
    try {
      setLoading(true)
      setError('')
      await deleteImageClassifierCategory(selectedCategory.id, selectedCategory.signature_id)
      if (!isMounted()) return
      setOpenCategoryDelete(false)
      populateImageClassifierCategory()
      populateImageClassifierEntries()
      setSelectedCategory(null)
    } catch (e) {
      if (!isMounted()) return
      setError('Error Deleting Category, please try again later')
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (searchTerm.length === 0) {
      setFilteredEntries(imageClassifierEntries.filter((entry) => entry.category_id === selectedCategory?.id))
    }
  }, [searchTerm, imageClassifierEntries, selectedCategory?.id])

  useEffect(() => {
    if (searchCategory.length === 0) {
      setFilteredCategories(imageClassifierCategories)
    }
  }, [searchCategory, imageClassifierCategories])

  useEffect(() => {
    setFilteredEntries(imageClassifierEntries.filter((entry) => entry.category_id === selectedCategory?.id))
  }, [imageClassifierEntries, selectedCategory])

  const populateSignatures = useCallback(async () => {
    try {
      const data = await getSignatures()
      if (!isMounted()) return
      setSignatures(data.signatures.filter((sig) => !sig.isCategory))
    } catch (e) {
      console.error(e)
    }
  }, [isMounted])

  const populateImageClassifierEntries = useCallback(async () => {
    try {
      const data = await getImageClassifierEntries()
      if (!isMounted()) return
      setImageClassifierEntries(data.entries)
    } catch (e) {
      console.error(e)
    }
  }, [isMounted])

  const populateImageClassifierCategory = useCallback(async () => {
    try {
      const data = await getImageClassifierCategory()
      if (!isMounted()) return
      setImageClassifierCategories(data.entries)
      setFilteredCategories(data.entries)
    } catch (e) {
      console.error(e)
    }
  }, [isMounted])

  useEffect(() => {
    setLoading(true)
    populateImageClassifierCategory()
    populateImageClassifierEntries()
    populateSignatures()
    setLoading(false)
  }, [populateSignatures, populateImageClassifierEntries, populateImageClassifierCategory])

  const handleStatsExpand = () => {
    setStatsExpanded(!statsExpanded)
  }

  return (
    <div>
      <Grid container justifyContent="center" sx={{ mt: 3 }} padding={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item>
              <Typography variant="h3">Image Classifier</Typography>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mt: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
        </Grid>
        {user?.role === 0 && (
          <Grid container justifyContent="center" sx={{ mt: 5 }}>
            <Tabs value={selector} onChange={handleSelector}>
              <Tab label="Test" />
              <Tab label="Configure" />
            </Tabs>
          </Grid>
        )}
        {selector === 0 && (
          <Grid>
            <Grid container justifyContent="center" sx={{ mt: 5 }}>
              <Grid container justifyContent="center" sx={{ mt: 5 }}>
                <Grid item>
                  <Typography>
                    The Image Classifier uses AI technology to help the Categorisation Team determine what a website
                    should be classified as based on screenshots containing various shapes and images.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" sx={{ mt: 5 }}>
              <Grid item padding={2}>
                <Typography>Website/URL:</Typography>
              </Grid>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleQueryOpenClip()
                }}
              >
                <Grid container justifyContent="center">
                  <Grid item>
                    <TextField onChange={(e) => handleChangeEntry(e.target.value)} value={entry} />
                  </Grid>
                  <Grid item padding={1}>
                    <Button disabled={loading || entry.length < 2} variant="contained" type="submit">
                      Analyse
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Grid item pl={15} pt={2}>
                <Typography>Image Analysis:</Typography>
              </Grid>
              <Grid item padding={1}>
                <Button variant="contained" component="label" disabled={loading}>
                  Upload
                  <input type="file" hidden accept=".png,.jpg,.jpeg" ref={fileUpload} onChange={handleUploadImage} />
                </Button>
              </Grid>
              {user?.role === 0 && (
                <Grid item padding={1} pl={15}>
                  <Button variant="contained" component="label" disabled={loading} onClick={handleReloadTerms}>
                    Reload Terms
                  </Button>
                </Grid>
              )}
            </Grid>
            {loading && (
              <Grid item textAlign="center" padding={5}>
                <CircularProgress />
              </Grid>
            )}
            {result && (
              <Paper sx={{ mt: 6, minWidth: '65%' }} elevation={8}>
                <Grid container justifyContent="center" direction="column" pl={2} pr={2} pt={1} pb={1}>
                  <Grid item padding={2}>
                    <Typography variant="h6">Result:</Typography>
                  </Grid>
                  {result.encoded_image && (
                    <>
                      <Grid item width="100%" textAlign="center">
                        <img
                          src={`data:image/jpeg;base64, ${result.encoded_image}`}
                          alt="Result"
                          style={{ alignSelf: 'center' }}
                        />
                        <Typography variant="subtitle2">Image Classifier is trained on images in 360p</Typography>
                      </Grid>
                      <Grid item padding={2}>
                        <Typography variant="overline">
                          Run phrase against image (each line is treated separately phrase):
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={queryEntry}
                          multiline
                          minRows={3}
                          onChange={(e) => handleChangePhrase(e.target.value)}
                        />
                      </Grid>
                      <Grid item>
                        <Grid container>
                          <Grid item padding={1}>
                            <Button variant="contained" onClick={handleClearPhrase}>
                              Clear
                            </Button>
                          </Grid>
                          <Grid item padding={1}>
                            <Button variant="contained" onClick={handleOpenClipTerms}>
                              Compare
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item pt={3}>
                    <Paper>
                      <TableContainer>
                        <Table size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Category</TableCell>
                              <TableCell>Similarity</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(result.clip_scores).map((score) => (
                              <TableRow>
                                <TableCell>{score}</TableCell>
                                <TableCell width="800p">
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <LinearProgress
                                      variant="determinate"
                                      value={result.clip_scores[score]}
                                      sx={{ width: '97%', mr: 1, height: '13px' }}
                                    />
                                    {result.clip_scores[score]}%
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Grid>
                  <Grid item pt={3}>
                    <Accordion variant="outlined" expanded={statsExpanded}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        onClick={handleStatsExpand}
                      >
                        <Typography sx={{ mx: 2 }}>Classifier Stats</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer>
                          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableBody>
                              <TableRow>
                                <TableCell width="50%">Screenshot Time:</TableCell>
                                <TableCell>{result.details.screenshot} seconds</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Clip Query Time:</TableCell>
                                <TableCell>{result.details.clip_query} seconds</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>GPU used:</TableCell>
                                <TableCell>{result.details.CUDA ? 'True' : 'False'}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Grid>
        )}
        {selector === 1 && (
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6} padding={3}>
              <Grid container spacing={1} alignItems="center" sx={{ mb: 2 }}>
                <Grid item>
                  <Typography variant="h6">Categories</Typography>
                </Grid>
                <Grid item>
                  <Fab
                    sx={{ mx: 2 }}
                    color="primary"
                    size="small"
                    disabled={loading}
                    onClick={handleCreateCategoryOpen}
                  >
                    <AddIcon />
                  </Fab>
                </Grid>
                <Grid item>
                  <SearchBar button value={searchCategory} dark={!darkMode} onChange={handleChangeSearchCategory} />
                </Grid>
              </Grid>
              <Grid item mt={2}>
                <ImageClassifierTable
                  classifierCategories={filteredCategories}
                  loading={loading}
                  selected={selectedCategory}
                  signatures={signatures}
                  dark={darkMode}
                  onClick={handleClickCategory}
                  onEdit={handleEditCategoryOpen}
                  onDelete={handleDeleteCategoryOpen}
                />
              </Grid>
            </Grid>
            {selectedCategory && (
              <Grid item xs={12} sm={6} md={6} lg={6} padding={3}>
                <Grid container spacing={1} alignItems="center" sx={{ mb: 2 }}>
                  <Grid item>
                    <Typography variant="h6">Entries</Typography>
                  </Grid>
                  <Grid item>
                    <Fab sx={{ mx: 2 }} color="primary" size="small" disabled={loading} onClick={handleCreateEntryOpen}>
                      <AddIcon />
                    </Fab>
                  </Grid>
                  <Grid item>
                    <SearchBar button value={searchTerm} dark={!darkMode} onChange={handleChangeSearchTerm} />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <ImageClassiferEntryTable
                    classifierEntries={filteredEntries}
                    loading={loading}
                    dark={darkMode}
                    onEdit={handleEditTermOpen}
                    onDelete={handleDeleteTermOpen}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        <AddClassiderTerm
          open={openEntryCreate}
          onClose={handleCreateEntryClose}
          editing={editing}
          loading={loading}
          error={error}
          classifierEntry={selectedEntry}
          onAdd={handleAddTerm}
          onEdit={handleEditTerm}
        />
        <AddClassifierCategory
          open={openCategoryCreate}
          onClose={handleCreateCategoryClose}
          editing={editing}
          error={error}
          loading={loading}
          classifierCategory={selectedCategory}
          signatures={signatures}
          onAdd={handleAddCategory}
          onEdit={handleEditCategory}
        />
        <DeleteConfirmDialog
          open={openEntryDelete}
          onClose={() => setOpenEntryDelete(false)}
          loading={loading}
          error={error}
          onDelete={handleDeleteTerm}
          name={`${selectedEntry?.term}`}
        />
        <DeleteConfirmDialog
          open={openCategoryDelete}
          onClose={() => setOpenCategoryDelete(false)}
          loading={loading}
          error={error}
          onDelete={handleDeleteCategory}
          name={`${selectedCategory?.label} (This will remove all associated terms)`}
        />
      </Grid>
    </div>
  )
}

export default ImageClassifier
