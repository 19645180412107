import React, { useEffect, useMemo } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline'
import darkScrollbar from '@mui/material/darkScrollbar'

import NavAppBar from './components/NavAppBar.tsx'

import Signatures from './routes/Signatures.tsx'
import Home from './routes/Home.tsx'
import Signature from './routes/Signature.tsx'
import Search from './routes/Search.tsx'
import SignatureTree from './routes/SignatureTree.tsx'
import Reports from './routes/Reports.tsx'
import Mappings from './routes/Mappings.tsx'
import Keywords from './routes/Keywords.tsx'
import Directives from './routes/Directives.tsx'
import Suggestions from './routes/Suggestions.tsx'
import MonitorModeration from './routes/MonitorModeration.tsx'
import AppindexManagerIndex from './routes/AppindexManagerIndex.tsx'
import ImageClassifier from './routes/ImageClassifier.tsx'
import Blockman from './routes/Blockman.tsx'

import { setHeader } from './util/Api.tsx'
import useStore, { setStoreDefaults } from './util/Store.tsx'
import LoginOverlay from './util/LoginOverlay.tsx'
import SignaturesPrint from './routes/SignaturesPrint.tsx'
import WeightedPhrases from './routes/WeightedPhrases.tsx'
import Applications from './routes/Applications.tsx'
import GenericLists from './routes/GenericLists.tsx'
import QueryProviders from './routes/QueryProviders.tsx'
import UserAdmin from './routes/UserAdmin.tsx'

// Set the state manager store defaults
const storeState = setStoreDefaults({
  user: null,
  token: null,
  darkMode: false,
  cookies: true,
})

setHeader('Authorization', storeState.token)

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#007acc',
      light: '#008eee',
      dark: '#0077c8',
    },
    secondary: {
      main: '#8000EE',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
  },
})

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#007acc',
      light: '#008eee',
      dark: '#0077c8',
    },
    secondary: {
      main: '#8000EE',
    },
    success: {
      main: '#357a38',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff8f00',
      contrastText: '#fff',
    },
    background: {
      default: '#1e1e1e',
      paper: '#252526',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar(),
      },
    },
  },
})

const App = () => {
  const [darkMode, setDarkMode] = useStore('darkMode')

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setDarkMode(true)
    }
  }, [setDarkMode])

  const themeToUse = useMemo(() => (darkMode || prefersDarkMode ? darkTheme : lightTheme), [prefersDarkMode, darkMode])

  return (
    <BrowserRouter>
      <ThemeProvider theme={themeToUse}>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
          <CssBaseline />
          <NavAppBar />
          <LoginOverlay />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/signatures" component={Signatures} />
            <Route exact path="/appindex-manager" component={AppindexManagerIndex} />
            <Route path="/suggestions" component={Suggestions} />
            <Route path="/mappings" component={Mappings} />
            <Route path="/keywords" component={Keywords} />
            <Route path="/signature/:id/:ref" component={Signature} />
            <Route path="/signature/:id" component={Signature} />
            <Route path="/signature-tree/:id" component={SignatureTree} />
            <Route path="/signature-tree" component={SignatureTree} />
            <Route path="/image-classifier" component={ImageClassifier} />
            <Route path="/search/:url" component={Search} />
            <Route path="/search" component={Search} />
            <Route path="/query-providers/:url" component={QueryProviders} />
            <Route path="/reports" component={Reports} />
            <Route path="/print" component={SignaturesPrint} />
            <Route path="/content-modifications" component={Directives} />
            <Route path="/weighted-phrases" component={WeightedPhrases} />
            <Route path="/monitor-moderation" component={MonitorModeration} />
            <Route path="/applications" component={Applications} />
            <Route path="/generic-lists" component={GenericLists} />
            <Route path="/blockman" component={Blockman} />
            <Route path="/useradmin" component={UserAdmin} />
          </Switch>
        </QueryParamProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
