import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
import useIsMounted from 'react-is-mounted-hook'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import Alert from '@mui/material/Alert'

import AddIcon from '@mui/icons-material/Add'

import SignatureTable from '../components/SignatureTable.tsx'
import SearchBar from '../components/SearchBar.tsx'
import SignatureCreate from '../components/SignatureCreate.tsx'

import { getSignatures, Signature } from '../util/Api.tsx'
import handleError from '../util/Error.tsx'
import useStore from '../util/Store.tsx'

interface SearchSignature extends Signature {
  search: string
}

const Signatures = () => {
  const isMounted = useIsMounted()
  const history = useHistory()
  const [user] = useStore('user')
  const [darkMode] = useStore('darkMode')
  const [signatures, setSignatures] = useState<SearchSignature[]>([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [shownSignatures, setShownSignatures] = useState<SearchSignature[]>([])
  const [createOpen, setCreateOpen] = useState(false)
  const [error, setError] = useState('')

  const populateSignatures = useCallback(
    async (searchString?: string) => {
      try {
        setLoading(true)
        const data = await getSignatures()
        if (!isMounted()) return
        const signatures = data.signatures.map((sig) => ({
          ...sig,
          search: `${sig.id} ${sig.name} ${sig.url}`.toLowerCase(),
        }))

        setSignatures(signatures)
        if (searchString) {
          setShownSignatures(signatures.filter((sig) => sig.search.includes(searchString.toLowerCase())))
          return
        }
        setShownSignatures(signatures)
      } catch (err) {
        const { msg } = handleError(err)
        if (!isMounted()) return
        setError(msg)
      } finally {
        setLoading(false)
      }
    },
    [isMounted]
  )

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if (e.target.value === '') {
      setShownSignatures(signatures)
    }
  }

  const handleSubmitSearch = () => {
    setShownSignatures(signatures.filter((sig) => sig.search.includes(search.toLowerCase())))
  }

  const handleSignatureClick = (id: string) => {
    history.push(`/signature/${id}`)
  }

  const handleOpenCreateDialog = () => {
    setCreateOpen(true)
  }

  const handleCloseCreateDialog = () => {
    setCreateOpen(false)
  }

  const handleAfterCreateSiganture = (signatureId: string) => {
    history.push(`/signature/${signatureId}`)
  }

  useEffect(() => {
    populateSignatures()
  }, [populateSignatures])

  return (
    <div>
      <Grid container justifyContent="center" sx={{ mt: 3 }} padding={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item>
              <Typography variant="h3">Appindex Signatures</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" padding={1} spacing={2} sx={{ mt: 3, mb: 3 }}>
            {user?.role === 0 && (
              <Grid item>
                <Fab color="primary" size="small" disabled={loading} onClick={handleOpenCreateDialog}>
                  <AddIcon />
                </Fab>
              </Grid>
            )}
            <Grid item>
              <SearchBar
                value={search}
                dark={!darkMode}
                button
                onChange={handleChangeSearch}
                onSubmit={handleSubmitSearch}
                onClickButton={handleSubmitSearch}
              />
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                target="_blank"
                rel="noopener noreferrer"
                href="/print"
              >
                Generate PDF
              </Button>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            {error && (
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="start" spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SignatureTable
                loading={loading}
                shownSignatures={shownSignatures}
                signatures={signatures}
                onClick={handleSignatureClick}
              />
              <SignatureCreate
                open={createOpen}
                onClose={handleCloseCreateDialog}
                onSave={handleAfterCreateSiganture}
                signatures={signatures}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Signatures
