import React, { useState } from 'react'
import { useHistory } from 'react-router'

import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import LogoutIcon from '@mui/icons-material/Logout'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import PublishIcon from '@mui/icons-material/Publish'
import UploadIcon from '@mui/icons-material/CloudUpload'
import MailIcon from '@mui/icons-material/Mail'
import ReciptIcon from '@mui/icons-material/Receipt'
import DownIcon from '@mui/icons-material/ThumbDown'
import AppsIcon from '@mui/icons-material/Apps'
import MapIcon from '@mui/icons-material/Map'
import KeyIcon from '@mui/icons-material/Key'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import BarChartIcon from '@mui/icons-material/BarChart'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import HistoryIcon from '@mui/icons-material/History'

import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import PublishDialog from './PublishDialog.tsx'
import PublishBoxesDialog from './PublishBoxesDialog.tsx'
import AvatarLetters from './AvatarLetters.tsx'
import StatisticsDialog from './StatisticsDialog.tsx'

import useStore from '../util/Store.tsx'
import { setHeader } from '../util/Api.tsx'
import handleError from '../util/Error.tsx'
import QueryProvidersDialog from './QueryProvidersDialog.tsx'
import RollbackDialog from './RollbackDialog.tsx'

interface AccountButtonProps {
  suggestionCount?: number | null
  onClickDownvotes: () => void
}

const AccountButton = ({ suggestionCount = null, onClickDownvotes = () => {} }: AccountButtonProps) => {
  const history = useHistory()
  const [, setToken] = useStore('token')
  const [user, setUser] = useStore('user')
  const [darkMode, setDarkMode] = useStore('darkMode')
  const [statisticsOpen, setStatisticsOpen] = useState(false)
  const [publishOpen, setPublishOpen] = useState(false)
  const [publishBoxesOpen, setPublishBoxesOpen] = useState(false)
  const [queryProvidersOpen, setQueryProvidersOpen] = useState(false)
  const [rollbackOpen, setRollbackOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleTogglePublishOpen = () => {
    setPublishOpen(!publishOpen)
  }

  const handleTogglePublishBoxesOpen = () => {
    setPublishBoxesOpen(!publishBoxesOpen)
  }

  const handleToggleRollbackOpen = () => {
    setRollbackOpen(!rollbackOpen)
  }

  const handleToggleStatisticsOpen = () => {
    setStatisticsOpen(!statisticsOpen)
  }

  const handleQueryPorvidersOpen = () => {
    setQueryProvidersOpen(!queryProvidersOpen)
  }

  const handleNavSuggestions = () => {
    history.push('/suggestions')
  }

  const handleNavReports = () => {
    history.push('/reports')
  }

  const handleNavSignatures = () => {
    history.push('/signatures')
  }

  const handleNavMappings = () => {
    history.push('/mappings')
  }

  const handleNavKeywords = () => {
    history.push('/keywords')
  }

  const handleClickAdmin = () => {
    history.push('/useradmin')
  }

  const handleClickDownvotes = () => {
    onClickDownvotes()
  }

  const handleLogout = async () => {
    try {
      setToken(null)
      setUser(null)
      setHeader('Authorization', '')
      window.history.pushState('', '', '/_gcp_iap/clear_login_cookie')
      window.location.reload()
    } catch (err) {
      handleError(err)
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', ml: 1 }}>
        <AvatarLetters
          email={user?.email || 'AA@familyzone.com'}
          badgeContent={suggestionCount}
          onClick={handleClick}
        />
      </Box>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Grid container alignItems="center" padding={2}>
          <Grid item>
            <Typography> {user?.email}</Typography>
            <Typography variant="caption">
              {(() => {
                switch (user?.role) {
                  case 0:
                    return 'Admin'
                  case 3:
                    return 'Blockman View-Only'
                  default:
                    return 'Appindex View-Only'
                }
              })()}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        {user?.role === 0 && (
          <>
            <MenuItem onClick={handleClickAdmin} sx={{ marginTop: '6px' }}>
              <ListItemIcon>
                <LocalPoliceIcon fontSize="small" />
              </ListItemIcon>
              User Administration
            </MenuItem>
            <Divider />
          </>
        )}
        <MenuItem onClick={() => setDarkMode(!darkMode)}>
          <FormControlLabel
            aria-readonly
            control={<Switch readOnly color="primary" checked={Boolean(darkMode)} />}
            label=""
          />
          Dark Mode
        </MenuItem>
        {user?.role === 0 && (
          <MenuItem onClick={handleTogglePublishOpen}>
            <ListItemIcon>
              <PublishIcon fontSize="small" />
            </ListItemIcon>
            Publish
          </MenuItem>
        )}
        {user?.role === 0 && (
          <MenuItem onClick={handleTogglePublishBoxesOpen}>
            <ListItemIcon>
              <UploadIcon fontSize="small" />
            </ListItemIcon>
            Update Boxes
          </MenuItem>
        )}
        {user?.role === 0 && (
          <MenuItem onClick={handleToggleRollbackOpen}>
            <ListItemIcon>
              <HistoryIcon fontSize="small" />
            </ListItemIcon>
            Rollback
          </MenuItem>
        )}
        <Divider />
        <MenuItem sx={{ display: { xs: 'flex', md: 'none' } }} onClick={handleNavSignatures}>
          <ListItemIcon>
            <AppsIcon fontSize="small" />
          </ListItemIcon>
          Signatures
        </MenuItem>
        <MenuItem sx={{ display: { xs: 'flex', md: 'none' } }} onClick={handleNavMappings}>
          <ListItemIcon>
            <MapIcon fontSize="small" />
          </ListItemIcon>
          Mappings
        </MenuItem>
        <MenuItem sx={{ display: { xs: 'flex', md: 'none' } }} onClick={handleNavKeywords}>
          <ListItemIcon>
            <KeyIcon fontSize="small" />
          </ListItemIcon>
          Keywords
        </MenuItem>
        {user?.role === 0 && (
          <MenuItem onClick={handleClickDownvotes}>
            <ListItemIcon>
              <DownIcon fontSize="small" />
            </ListItemIcon>
            Downvotes
          </MenuItem>
        )}
        {user?.role === 0 && (
          <MenuItem onClick={handleNavReports}>
            <ListItemIcon>
              <ReciptIcon fontSize="small" />
            </ListItemIcon>
            Reports
          </MenuItem>
        )}
        <MenuItem onClick={handleNavSuggestions}>
          <ListItemIcon>
            <MailIcon fontSize="small" />
          </ListItemIcon>
          Requests {user?.role === 0 && suggestionCount ? `(${suggestionCount})` : ''}
        </MenuItem>
        <MenuItem onClick={handleQueryPorvidersOpen}>
          <ListItemIcon>
            <TravelExploreIcon fontSize="small" />
          </ListItemIcon>
          Query Providers
        </MenuItem>
        <MenuItem onClick={handleToggleStatisticsOpen}>
          <ListItemIcon>
            <BarChartIcon fontSize="small" />
          </ListItemIcon>
          Statistics
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <PublishDialog open={publishOpen} onClose={handleTogglePublishOpen} />
      <PublishBoxesDialog open={publishBoxesOpen} onClose={handleTogglePublishBoxesOpen} />
      <StatisticsDialog open={statisticsOpen} onClose={handleToggleStatisticsOpen} />
      <QueryProvidersDialog open={queryProvidersOpen} onClose={handleQueryPorvidersOpen} />
      <RollbackDialog open={rollbackOpen} onClose={handleToggleRollbackOpen} />
    </>
  )
}

export default AccountButton
