import axios from 'axios'
import { AddApp } from '../components/AddAppControl.tsx'
import { AnyError } from './Error.tsx'

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : ''
const api = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
  timeout: 0,
})

var loginQue: Function[] = []

export function loginQueHasItems() {
  return loginQue.length !== 0
}

export function addLoginQue(cb: Function) {
  loginQue.push(cb)
}

export function runLoginQue() {
  loginQue.forEach((item) => item())
  loginQue = []
}

export interface KeywordEntry {
  id: string
  type: string
  value: string
}

export interface Keyword {
  deprecated: boolean
  description: string
  faviconUrl: null | string
  id: string
  name: string
}

export interface KeywordTestResult {
  keyword_signature: string
  reason: string
  search: string
  words: string[]
}

export interface VertexTestResult {
  scores: { [key: string]: any }
  search: string
}

export interface Appstore {
  id: string
  type: string
  modified?: null | number | Date
  controlapp?: string
}

export interface AppstoreSearch {
  id: string
  type: string
  modified?: null | number | Date
  signature: string
}

export interface DecodedUser {
  name: string
  email: string
  enabled: boolean
  admin: boolean
  appindexAdmin: boolean
  customerId: string
  lwToken: string
  supportAdmin: boolean
  demoAccount: boolean
  fzEmail: boolean
}

export interface SuggestionMessage {
  message: string
  email: string
  created: number
}

export interface Suggestion {
  id: string
  created: number
  email: string
  type: string
  edit: string
  data: { [key: string]: any }
  messages: SuggestionMessage[]
  draft: boolean
  done: boolean
  wontdo: boolean
}

interface OldUrlInformation {
  signatureId: string
  url: string
  provider?: string
}

export interface Mapping {
  signatureId: string
  providerId: string
  providerCategory: string
  providerDescription: string
  ranking: number
  ttl: number
}

export interface Url {
  dnsInvalid: boolean
  entryType: 'ip' | 'website'
  expires: number
  imported: number
  invalidDuplicate: boolean
  primarySig: boolean
  providerCategory: string
  providerId: string
  signatureId: string
  userDefinedInvalid: boolean
  userDownVotes: number
  website: string
  providerDescription: string
}

export interface Criteria {
  id: string
  criteria: { [key: string]: any }
}

export interface Signature {
  category: string | null
  description: string
  enabled: boolean
  faviconUrl: string | null
  id: string
  isCategory: boolean
  isHighLevelSignature: boolean
  isLowLevelSignature: boolean
  isSubCategory: boolean
  minVersion: number
  name: string
  noise: boolean
  dependencies: string[]
  criteria: Criteria[]
  nonExclusiveCriteria: Criteria[]
  url: string | null
  focus: boolean
  reporting: boolean
  hidden: boolean
  tags: string[]
  appstores: Appstore[]
}

export interface SignatureUrlInfo {
  url: string
  signatureId: string | null
  primarySig: boolean
  valid?: boolean
  add?: boolean
  remove?: boolean
  error?: string
  unknown?: boolean
  similar?: boolean
}

export interface Downvote {
  category: string
  downvotes: number
  website: string
}

export function setHeader(field: string, value: string) {
  api.defaults.headers[field] = value
}

/**
 * IAP injects the necessary headers automatically for the
 * server to pick up and authorize
 */
export async function iapLogin(): Promise<{
  user: DecodedUser
  token: string
  msg: string
}> {
  try {
    const res = await api.get('/api/user/login/iap')
    return res.data
  } catch (err) {
    throw err
  }
}

export async function login(data: { email: string; password: string }): Promise<{
  user: DecodedUser
  token: string
  msg: string
}> {
  try {
    const res = await api.post('/api/user/login', data)
    return res.data
  } catch (err) {
    throw err
  }
}

export async function loginGoogle({
  callback,
  code,
  whitelabel,
}: {
  callback: string
  code: string
  whitelabel: string
}): Promise<{
  user: DecodedUser
  token: string
  msg: string
}> {
  try {
    const res = await api.post('/api/user/login/google', {
      callback,
      code,
      whitelabel,
    })
    return res.data
  } catch (err) {
    throw err
  }
}

export async function getGoogleClientId(): Promise<{
  clientId: string
}> {
  try {
    const res = await api.get('/api/google')
    return res.data
  } catch (err) {
    throw err
  }
}

export async function getIsProd(): Promise<{
  isProd: boolean
}> {
  try {
    const res = await api.get('/api/google/prod')
    return res.data
  } catch (err) {
    throw err
  }
}

export async function validateToken(): Promise<{
  msg: string
}> {
  try {
    const res = await api.get('/api/user/validate')
    return res.data
  } catch (err) {
    throw err
  }
}

export function handleApiError(err: AnyError | any, cb: Function): Promise<any> {
  return new Promise((resolve, reject) => {
    if (err?.response?.status !== 401) {
      return reject(err)
    }
    addLoginQue(() => {
      return cb().then(resolve).catch(reject)
    })
  })
}

export async function countPendingSuggestions(): Promise<{
  msg: string
  count: number
}> {
  try {
    const res = await api.get(`/api/suggestions/count`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => countPendingSuggestions())
  }
}

export async function getSuggestionById(id: string): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.get(`/api/suggestions/id/${encodeURIComponent(id)}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getSuggestionById(id))
  }
}

export async function getAllSuggestions(
  limit: number,
  page: number,
  search: string
): Promise<{
  msg: string
  suggestions: Suggestion[]
  total: number
}> {
  try {
    const res = await api.get(`/api/suggestions/all/${page}`, {
      params: {
        search,
        limit,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => getAllSuggestions(limit, page, search))
  }
}

export async function getSuggestionsByEmail(
  limit: number,
  page: number,
  search: string
): Promise<{
  msg: string
  suggestions: Suggestion[]
  total: number
}> {
  try {
    const res = await api.get(`/api/suggestions/email/${page}`, {
      params: {
        search,
        limit,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => getSuggestionsByEmail(limit, page, search))
  }
}

export async function getSuggestionsByPending(
  limit: number,
  page: number,
  search: string
): Promise<{
  msg: string
  suggestions: Suggestion[]
  total: number
}> {
  try {
    const res = await api.get(`/api/suggestions/pending/${page.toString()}`, {
      params: {
        search,
        limit,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => getSuggestionsByPending(limit, page, search))
  }
}

export async function getSuggestionsByDone(
  limit: number,
  page: number,
  search: string
): Promise<{
  msg: string
  suggestions: Suggestion[]
  total: number
}> {
  try {
    const res = await api.get(`/api/suggestions/done/${page.toString()}`, {
      params: {
        search,
        limit,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => getSuggestionsByDone(limit, page, search))
  }
}

export async function getSuggestionsByWontdo(
  limit: number,
  page: number,
  search: string
): Promise<{
  msg: string
  suggestions: Suggestion[]
  total: number
}> {
  try {
    const res = await api.get(`/api/suggestions/wontdo/${page.toString()}`, {
      params: {
        search,
        limit,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => getSuggestionsByWontdo(limit, page, search))
  }
}

export async function addEditAppstoresSuggestion({
  controlId,
  controlName,
  newControl,
  controlFavicon,
  signatureId,
  appstores,
  message,
  category,
  ticket,
}: {
  controlId: string
  controlName: string
  newControl: boolean
  controlFavicon: string
  category: string
  signatureId: string
  appstores: Appstore[]
  message: string
  ticket: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.put(`/api/suggestions/edit-appstores`, {
      controlId,
      controlName,
      newControl,
      controlFavicon,
      signatureId,
      appstores,
      message,
      category,
      ticket,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () =>
      addEditAppstoresSuggestion({
        controlId,
        controlName,
        newControl,
        controlFavicon,
        signatureId,
        appstores,
        message,
        category,
        ticket,
      })
    )
  }
}

export async function addOtherSuggestion({ message, ticket }: { message: string; ticket: number }): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.put(`/api/suggestions/other-request`, { message, ticket })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addOtherSuggestion({ message, ticket }))
  }
}

export async function editOtherSuggestion({ id, message }: { id: string; message: string }): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.post(`/api/suggestions/edit-other-request`, {
      id,
      message,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editOtherSuggestion({ id, message }))
  }
}

export async function addKeywordsSuggestion({
  keywordId,
  keyword_Entry,
  keyword_Type,
  message,
  ticket,
}: {
  keywordId: string
  keyword_Entry: string[]
  keyword_Type: string | null
  message: string
  ticket: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.put(`/api/suggestions/add-keywords`, {
      keywordId,
      keyword_Entry,
      keyword_Type,
      message,
      ticket,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addKeywordsSuggestion({ keywordId, keyword_Entry, keyword_Type, message, ticket }))
  }
}

export async function editAddKeywordsSuggestion({
  id,
  keywordId,
  keywordEntry,
  keywordType,
  ticket,
}: {
  id: string
  keywordId: string
  keywordEntry: string[]
  keywordType: string
  ticket: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.post(`/api/suggestions/edit-add-keywords/${id}`, {
      id,
      keywordId,
      keywordEntry,
      keywordType,
      ticket,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editAddKeywordsSuggestion({ id, keywordId, keywordEntry, keywordType, ticket }))
  }
}

export async function editEditAppstoresSuggestion({
  id,
  appstores,
  ticket,
}: {
  id: string
  appstores: Appstore[]
  ticket: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.post(`/api/suggestions/edit-appstores/${id}`, { appstores, ticket })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editEditAppstoresSuggestion({ id, appstores, ticket }))
  }
}

export async function editNewAppstoreSuggestion({
  id,
  controlId,
  controlName,
  controlFavicon,
  category,
  ticket,
}: {
  id: string
  controlId: string
  controlName: string
  controlFavicon: string
  category: string
  ticket: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.post(`/api/suggestions/new-appstore/${id}`, {
      controlId,
      controlName,
      controlFavicon,
      category,
      ticket,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () =>
      editNewAppstoreSuggestion({ id, controlId, controlName, controlFavicon, category, ticket })
    )
  }
}

export async function addCreateSignatureSuggestion({
  signature,
  urls,
  message,
  ticket,
}: {
  signature: Signature
  urls: string[]
  message: string
  ticket: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.put(`/api/suggestions/create-signature`, { signature, urls, message, ticket })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addCreateSignatureSuggestion({ signature, urls, message, ticket }))
  }
}

export async function editCreateSignatureSuggestion({
  id,
  signature,
  urls,
  ticket,
}: {
  id: string
  signature: Signature
  urls: string[]
  ticket: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.post(`/api/suggestions/create-signature/${id}`, { signature, urls, ticket })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editCreateSignatureSuggestion({ id, signature, urls, ticket }))
  }
}

export async function addRecatUrlsSuggestion({
  signatureId,
  urls,
  message,
  ticket,
}: {
  signatureId: string
  urls: string[]
  message: string
  ticket: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.put(`/api/suggestions/recat-urls`, { signatureId, urls, message, ticket })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addRecatUrlsSuggestion({ signatureId, urls, message, ticket }))
  }
}

export async function editRecatUrlsSuggestion({
  id,
  signatureId,
  urls,
  oldUrls,
  ticket,
}: {
  id: string
  signatureId: string
  urls: string[]
  oldUrls: OldUrlInformation[]
  ticket: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.post(`/api/suggestions/recat-urls/${id}`, { signatureId, urls, oldUrls, ticket })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editRecatUrlsSuggestion({ id, signatureId, urls, oldUrls, ticket }))
  }
}

export async function addEditSignatureSuggestion({
  signature,
  message,
  ticket,
}: {
  signature: Signature
  message: string
  ticket: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.put(`/api/suggestions/edit-signature`, { signature, message, ticket })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addEditSignatureSuggestion({ signature, message, ticket }))
  }
}

export async function editEditSignatureSuggestion({
  id,
  signature,
  ticket,
}: {
  id: string
  signature: Signature
  ticket: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.post(`/api/suggestions/edit-signature/${id}`, { signature, ticket })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editEditSignatureSuggestion({ id, signature, ticket }))
  }
}

export async function addSuggestionMessage({
  id,
  message,
  ticket,
}: {
  id: string
  message: string
  ticket: any
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.put(`/api/suggestions/message/${id}`, { message, ticket })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addSuggestionMessage({ id, message, ticket }))
  }
}

export async function deleteSuggestion({ id }: { id: string }): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.delete(`/api/suggestions/${id}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteSuggestion({ id }))
  }
}

export async function editSuggestionMetadata({
  id,
  done,
  wontdo,
  message,
  ticket,
}: {
  id: string
  done?: boolean
  wontdo?: boolean
  message?: string
  ticket?: number
}): Promise<{
  msg: string
  suggestion: Suggestion
}> {
  try {
    const res = await api.post(`/api/suggestions/edit/${id}`, { done, wontdo, message, ticket })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editSuggestionMetadata({ id, done, wontdo, message, ticket }))
  }
}

export async function getKeywords(): Promise<{
  msg: string
  keywords: Keyword[]
}> {
  try {
    const res = await api.get('/api/keywords')
    return res.data
  } catch (err) {
    return handleApiError(err, () => getKeywords())
  }
}

export async function createKeyword({
  id,
  name,
  description,
}: {
  id: string
  name: string
  description: string
}): Promise<{
  msg: string
  keywords: Keyword[]
}> {
  try {
    const res = await api.put('/api/keywords', {
      id,
      name,
      description,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => createKeyword({ id, name, description }))
  }
}

export async function testKeywords({ keywordId, phrases }: { keywordId: string; phrases: string }): Promise<{
  msg: string
  results: KeywordTestResult[]
}> {
  try {
    const res = await api.post('/api/keywords/test', {
      data: {
        keywordId,
        phrases,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => testKeywords({ keywordId, phrases }))
  }
}

export async function testVertex({ phrases }: { phrases: string }): Promise<{
  msg: string
  results: VertexTestResult[]
}> {
  try {
    const res = await api.post('/api/keywords/vertex', {
      data: {
        phrases,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => testVertex({ phrases }))
  }
}

export async function deleteKeyword({ id }: { id: string }): Promise<{
  msg: string
  keywords: Keyword[]
}> {
  try {
    const res = await api.delete('/api/keywords', {
      data: {
        id,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteKeyword({ id }))
  }
}

export async function getKeywordEntries(): Promise<{
  msg: string
  keywordEntries: KeywordEntry[]
}> {
  try {
    const res = await api.get('/api/keywords/entries')
    return res.data
  } catch (err) {
    return handleApiError(err, () => getKeywordEntries())
  }
}

export async function createKeywordEntry({
  keywordId,
  type,
  value,
}: {
  keywordId: string
  type: string
  value: string
}): Promise<{
  msg: string
  keywordEntries: KeywordEntry[]
}> {
  try {
    const res = await api.put('/api/keywords/entries', {
      keywordId,
      type,
      value,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => createKeywordEntry({ keywordId, type, value }))
  }
}

export async function editKeywords({
  id,
  description,
  name,
  faviconDataUrl,
  deprecated,
}: {
  id: string
  description: string
  name: string
  faviconDataUrl: string | null
  deprecated: boolean
}): Promise<{
  msg: string
  keywordEntries: KeywordEntry[]
}> {
  try {
    const res = await api.post('/api/keywords/edit', {
      data: {
        id,
        description,
        name,
        faviconDataUrl,
        deprecated,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editKeywords({ id, description, name, faviconDataUrl, deprecated }))
  }
}

export async function deleteKeywordEntry({
  keywordId,
  type,
  value,
}: {
  keywordId: string
  type: string
  value: string
}): Promise<{
  msg: string
  keywordEntries: KeywordEntry[]
}> {
  try {
    const res = await api.delete('/api/keywords/entries', {
      data: {
        keywordId,
        type,
        value,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteKeywordEntry({ keywordId, type, value }))
  }
}

export async function addKeywordsBulk({
  id,
  keywordEntry,
  keywordType,
}: {
  id: string
  keywordEntry: string[]
  keywordType: string
}): Promise<{
  msg: string
}> {
  try {
    const res = await api.post(`/api/keywords/bulk`, { id, keywordEntry, keywordType })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addKeywordsBulk({ id, keywordEntry, keywordType }))
  }
}

export async function getMappings(): Promise<{
  msg: string
  mappings: Mapping[]
}> {
  try {
    const res = await api.get('/api/mappings')
    return res.data
  } catch (err) {
    return handleApiError(err, () => getMappings())
  }
}

export async function getSignatureMappings(signatureId: string): Promise<{
  msg: string
  mappings: Mapping[]
}> {
  try {
    const res = await api.get(`/api/mappings/signature/${encodeURIComponent(signatureId)}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getSignatureMappings(signatureId))
  }
}

export async function createMapping({
  signatureId,
  providerId,
  providerCategory,
  providerDescription,
  ttl,
  ranking,
}: {
  signatureId: string
  providerId: string
  providerCategory: string
  providerDescription: string
  ttl: number
  ranking: number
}): Promise<{
  msg: string
  mappings: Mapping[]
}> {
  try {
    const res = await api.put('/api/mappings', {
      signatureId,
      providerId,
      providerCategory,
      providerDescription,
      ttl,
      ranking,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () =>
      createMapping({ signatureId, providerId, providerCategory, providerDescription, ttl, ranking })
    )
  }
}

export async function editMappings({
  signatureId,
  providerId,
  providerCategory,
  providerDescription,
  ttl,
  ranking,
  editmapping,
}: {
  signatureId: string
  providerId: string
  providerCategory: string
  providerDescription: string
  ttl: number
  ranking: number
  editmapping: Mapping
}): Promise<{
  msg: string
  mappings: Mapping[]
}> {
  try {
    const res = await api.post('/api/mappings', {
      signatureId,
      providerId,
      providerCategory,
      providerDescription,
      ttl,
      ranking,
      editmapping,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () =>
      editMappings({ signatureId, providerId, providerCategory, providerDescription, ttl, ranking, editmapping })
    )
  }
}

export async function deleteMapping({
  signatureId,
  providerId,
  providerCategory,
}: {
  signatureId: string
  providerId: string
  providerCategory: string
}): Promise<{
  msg: string
  mappings: Mapping[]
}> {
  try {
    const res = await api.delete('/api/mappings', {
      data: {
        signatureId,
        providerId,
        providerCategory,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteMapping({ signatureId, providerId, providerCategory }))
  }
}

export async function getDetailedSignatures(): Promise<{
  msg: string
  signatures: Signature[]
}> {
  try {
    const res = await api.get('/api/signatures/details')
    return res.data
  } catch (err) {
    return handleApiError(err, () => getDetailedSignatures())
  }
}

export async function getSignatures(): Promise<{
  msg: string
  signatures: Signature[]
}> {
  try {
    const res = await api.get('/api/signatures')
    return res.data
  } catch (err) {
    return handleApiError(err, () => getSignatures())
  }
}

export async function getSignature(signatureId: string): Promise<{
  msg: string
  signature: Signature
  syncLabel: string
}> {
  try {
    const res = await api.get(`/api/signatures/${signatureId}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getSignature(signatureId))
  }
}

export async function autoUpdateSignatureCategory(signatureId: string): Promise<{
  msg: string
  signature: Signature
}> {
  try {
    const res = await api.post(`/api/signatures/${signatureId}/category`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => autoUpdateSignatureCategory(signatureId))
  }
}

interface EditSignature
  extends Omit<
    Signature,
    | 'id'
    | 'appstores'
    | 'faviconUrl'
    | 'isHighLevelSignature'
    | 'isLowLevelSignature'
    | 'minVersion'
    | 'dependencies'
    | 'nonExclusiveCriteria'
    | 'criteria'
    | 'isCategory'
    | 'isSubCategory'
  > {
  autoCategory?: boolean
  faviconDataUrl?: string | null
  consumer?: boolean
  isCategory?: boolean
  isSubCategory?: boolean
}

export async function editSignature(
  signatureId: string,
  {
    category,
    description,
    enabled,
    name,
    noise,
    url,
    isSubCategory,
    isCategory,
    autoCategory,
    faviconDataUrl,
    focus,
    tags,
    reporting,
    hidden,
  }: EditSignature
) {
  try {
    const res = await api.post(`/api/signatures/${signatureId}`, {
      category,
      description,
      enabled,
      name,
      noise,
      url,
      isSubCategory,
      isCategory,
      autoCategory,
      faviconDataUrl,
      focus,
      tags,
      reporting,
      hidden,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () =>
      editSignature(signatureId, {
        category,
        description,
        enabled,
        name,
        noise,
        url,
        isSubCategory,
        isCategory,
        autoCategory,
        faviconDataUrl,
        focus,
        tags,
        reporting,
        hidden,
      })
    )
  }
}

export async function deleteSignature(signatureId: string): Promise<{
  msg: string
}> {
  try {
    const res = await api.delete(`/api/signatures/${signatureId}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteSignature(signatureId))
  }
}

export async function createSignature(
  signatureId: string,
  {
    category,
    name,
    isSubCategory,
    isCategory,
    autoCategory,
  }: { category: string | null; name: string; isSubCategory?: boolean; isCategory?: boolean; autoCategory: boolean }
): Promise<{
  msg: string
  signature: Signature
}> {
  try {
    const res = await api.put(`/api/signatures/${signatureId}`, {
      category,
      name,
      isSubCategory,
      isCategory,
      autoCategory,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () =>
      createSignature(signatureId, { category, name, isSubCategory, isCategory, autoCategory })
    )
  }
}

export async function addSignatureCriteria(
  signatureId: string,
  criteria: { [key: string]: any }
): Promise<{
  msg: string
  signature: Signature
}> {
  try {
    const res = await api.put(`/api/signatures/${signatureId}/criteria`, {
      criteria,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addSignatureCriteria(signatureId, criteria))
  }
}

export async function deleteSignatureCriteria(
  signatureId: string,
  criteriaId: string
): Promise<{
  msg: string
  signature: Signature
}> {
  try {
    const res = await api.delete(`/api/signatures/${signatureId}/criteria`, {
      data: { criteriaId },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteSignatureCriteria(signatureId, criteriaId))
  }
}

export async function addSignatureNECriteria(
  signatureId: string,
  criteria: { [key: string]: any }
): Promise<{
  msg: string
  signature: Signature
}> {
  try {
    const res = await api.put(`/api/signatures/${signatureId}/nonexclusivecriteria`, {
      criteria,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addSignatureNECriteria(signatureId, criteria))
  }
}

export async function deleteSignatureNECriteria(
  signatureId: string,
  criteriaId: string
): Promise<{
  msg: string
  signature: Signature
}> {
  try {
    const res = await api.delete(`/api/signatures/${signatureId}/nonexclusivecriteria`, {
      data: { criteriaId },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteSignatureNECriteria(signatureId, criteriaId))
  }
}

export async function getSignatureURLsCount(signatureId: string): Promise<{
  msg: string
  count: number
}> {
  try {
    const res = await api.get(`/api/signatures/${signatureId}/urls-count`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getSignatureURLsCount(signatureId))
  }
}

export async function getSignatureURLs(
  signatureId: string,
  page: number
): Promise<{
  msg: string
  entries: Url[]
  limit: number
  total: number
}> {
  try {
    const res = await api.get(`/api/signatures/${signatureId}/urls`, {
      params: {
        page,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => getSignatureURLs(signatureId, page))
  }
}

export async function getAllSignatureURLs(signatureId: string): Promise<{
  msg: string
  entries: Url[]
}> {
  try {
    const res = await api.get(`/api/signatures/${signatureId}/urls/all`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getAllSignatureURLs(signatureId))
  }
}

export async function addSignatureURL(
  signatureId: string,
  url: string
): Promise<{
  msg: string
  url: Url
}> {
  try {
    const res = await api.put(`/api/signatures/${signatureId}/urls`, {
      url,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addSignatureURL(signatureId, url))
  }
}

export async function reclassifySignatureURL(
  signatureId: string,
  url: string,
  reclassify: string
): Promise<{
  msg: string
  url: Url
}> {
  try {
    const res = await api.post(`/api/signatures/${signatureId}/urls`, {
      url,
      reclassify,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => reclassifySignatureURL(signatureId, url, reclassify))
  }
}

export async function deleteSignatureURL(
  signatureId: string,
  url: string
): Promise<{
  msg: string
}> {
  try {
    const res = await api.delete(`/api/signatures/${signatureId}/urls`, {
      data: { url },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteSignatureURL(signatureId, url))
  }
}

export async function addSignatureDependencyBulk(
  signatureId: string,
  dependencies: string[]
): Promise<{
  msg: string
  signature: Signature
}> {
  try {
    const res = await api.put(`/api/signatures/${signatureId}/dependencies-bulk`, {
      dependencies,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addSignatureDependencyBulk(signatureId, dependencies))
  }
}

export async function addSignatureDependency(
  signatureId: string,
  dependency: string
): Promise<{
  msg: string
  signature: Signature
}> {
  try {
    const res = await api.put(`/api/signatures/${signatureId}/dependencies`, {
      dependency,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addSignatureDependency(signatureId, dependency))
  }
}

export async function deleteSignatureDependency(
  signatureId: string,
  dependency: string
): Promise<{
  msg: string
  signature: Signature
}> {
  try {
    const res = await api.delete(`/api/signatures/${signatureId}/dependencies`, {
      data: { dependency },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteSignatureDependency(signatureId, dependency))
  }
}

export async function deleteSignatureApp(
  controlId: string,
  signatureId: string,
  appId: string,
  type: string
): Promise<{
  msg: string
  signature: Signature
}> {
  try {
    const res = await api.delete(`/api/applications/app`, {
      data: { controlId, signatureId, appId, type },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteSignatureApp(controlId, signatureId, appId, type))
  }
}

export async function addSignatureApp(
  controlId: string,
  signatureId: string,
  appId: string,
  type: string
): Promise<{
  msg: string
  signature: Signature
}> {
  try {
    const res = await api.put(`/api/applications/app`, {
      controlId,
      signatureId,
      appId,
      type,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addSignatureApp(controlId, signatureId, appId, type))
  }
}

export async function getURLInfo(url: string): Promise<{
  similar: Url[]
  exact: Url[]
  total: number
  appStores: AppstoreSearch[]
}> {
  try {
    const res = await api.get(`/api/urls/info/${encodeURIComponent(url)}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getURLInfo(url))
  }
}

export async function getURLInfoQuick(url: string): Promise<{
  exact: Url[]
  valid: boolean
}> {
  try {
    const res = await api.get(`/api/urls/quick/${encodeURIComponent(url)}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getURLInfoQuick(url))
  }
}

export async function getDownvotes(human: boolean): Promise<{
  msg: string
  downvotes: Downvote[]
}> {
  try {
    const res = await api.get('/api/downvotes', { params: { human } })
    return res.data
  } catch (err) {
    return handleApiError(err, () => getDownvotes(human))
  }
}

export async function purgeDownvotes(): Promise<{
  msg: string
  downvotes: Downvote[]
}> {
  try {
    const res = await api.delete('/api/downvotes')
    return res.data
  } catch (err) {
    return handleApiError(err, () => purgeDownvotes())
  }
}

export async function purgeSingleDownvote(website: string): Promise<{
  msg: string
  downvotes: Downvote[]
}> {
  try {
    const res = await api.delete(`/api/downvotes/${website}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => purgeDownvotes())
  }
}

export async function snoozeDownvote(website: string): Promise<{
  msg: string
  downvotes: Downvote[]
}> {
  try {
    const res = await api.delete(`/api/downvotes/snooze/${website}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => snoozeDownvote(website))
  }
}

export async function publishSignatures(): Promise<{
  msg: string
}> {
  try {
    const res = await api.post('/api/publish')
    return res.data
  } catch (err) {
    return handleApiError(err, () => publishSignatures())
  }
}

// NOTE: Please add proper typings here. The sync route on the backend
// uses a spread operator for ...data which does not allow strict
// types.
export async function syncSignature(signatureId: string, merge: boolean) {
  try {
    const res = await api.post(`/api/sync/signature/${signatureId}`, {
      merge,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => syncSignature(signatureId, merge))
  }
}

export async function obtainDiff(signatureId: string) {
  try {
    const res = await api.get(`/api/sync/signature/diff/${signatureId}`)
    return res
  } catch (err) {
    return handleApiError(err, () => obtainDiff(signatureId))
  }
}

export async function cancelSyncSignature(signatureId: string) {
  try {
    const res = await api.delete(`/api/sync/signature/${signatureId}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => cancelSyncSignature(signatureId))
  }
}
// -----------

export async function updateBoxes(): Promise<{
  msg: string
}> {
  try {
    const res = await api.post('/api/publish/update-boxes')
    return res.data
  } catch (err) {
    return handleApiError(err, () => updateBoxes())
  }
}

export async function fetchFavicons(signatureId: string): Promise<{
  msg: string
}> {
  try {
    const res = await api.get(`/api/signatures/${signatureId}/fetch-favicon`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => fetchFavicons(signatureId))
  }
}

export async function publishVersion(): Promise<{
  msg: string
  version: {
    versionId: string
    creationDate: string
  }
}> {
  try {
    const res = await api.get('/api/publish/version')
    return res.data
  } catch (err) {
    return handleApiError(err, () => publishVersion())
  }
}

export async function manualSyncLivePublish(): Promise<{
  msg: string
}> {
  try {
    const res = await api.get('/api/publish/live-update')
    return res.data
  } catch (err) {
    return handleApiError(err, () => manualSyncLivePublish())
  }
}

export async function publishBoxes(): Promise<{
  msg: string
}> {
  try {
    const res = await api.post('/api/publish/update-boxes')
    return res.data
  } catch (err) {
    return handleApiError(err, () => publishBoxes())
  }
}

export interface WeightedPhrase {
  id: string
  modified: Date
  phrase: string[]
  weight: number
  signature: string
  banned: boolean
}

export async function getWeightedPhrases(): Promise<{ phrases: WeightedPhrase[] }> {
  try {
    const res = await api.get('/api/weighted-phrases')
    return res.data.phrases
  } catch (err) {
    return handleApiError(err, () => getWeightedPhrases())
  }
}

export async function addWeightedPhrases(
  phrase: string[],
  signature_id: string,
  weight: string,
  banned: boolean
): Promise<{ phrases: WeightedPhrase[] }> {
  try {
    const res = await api.put('/api/weighted-phrases', { phrase, signature_id, weight, banned })
    return res.data.phrases
  } catch (err) {
    return handleApiError(err, () => addWeightedPhrases(phrase, signature_id, weight, banned))
  }
}

export async function deleteWeightedPhrases(phrase: WeightedPhrase): Promise<{ phrases: WeightedPhrase[] }> {
  try {
    const res = await api.delete('/api/weighted-phrases', { data: { phrase } })
    return res.data.phrases
  } catch (err) {
    return handleApiError(err, () => deleteWeightedPhrases(phrase))
  }
}

export async function editWeightedPhrases(
  oldPhrase: WeightedPhrase | null,
  phrase: string[],
  signature_id: string,
  weight: string,
  banned: boolean
): Promise<{ phrases: WeightedPhrase[] }> {
  try {
    const res = await api.post('/api/weighted-phrases', { oldPhrase, phrase, signature_id, weight, banned })
    return res.data.phrases
  } catch (err) {
    return handleApiError(err, () => editWeightedPhrases(oldPhrase, phrase, signature_id, weight, banned))
  }
}

export interface StatisticEntry {
  provider: string
  count: number
}

export interface Statistic {
  name: string
  entries: StatisticEntry[]
}

export async function obtainStatistics(): Promise<{ statistics: Statistic[] }> {
  try {
    const res = await api.get(`/api/publish/statistics`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => obtainStatistics())
  }
}

export interface ReqStatistic {
  [key: string]: {
    done: number
    total: number
    wontdo: number
  }
}

export interface ReqStatistics {
  [key: string]: ReqStatistic[]
}

export async function obtainStatisticsRequests(): Promise<{ requests: ReqStatistics }> {
  try {
    const res = await api.get(`/api/publish/statistics/requests`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => obtainStatisticsRequests())
  }
}

export interface providerStatistics {
  provider: [{ [key: string]: number }]
  signature_id: string
  signature_category: string
  theme: boolean
  category: boolean
  name: string
}

export async function obtainProviderStatistics(): Promise<{ statistics: providerStatistics[] }> {
  try {
    const res = await api.get(`/api/publish/statistics/provider`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => obtainProviderStatistics())
  }
}

export interface JSONAction {
  id: string
  json: { [key: string]: any }
}

export interface JSONCondition {
  id: string
  type: string
  value: string
}

export interface Directive {
  id: string
  name: string
  description: string
  enabled: boolean
  favicon_url: string | null
  conditions: JSONCondition[]
  actions: JSONAction[]
}

export async function getDirectives(): Promise<{ directives: Directive[] }> {
  try {
    const res = await api.get(`/api/directives`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getDirectives())
  }
}

export async function addDirectives(
  id: string,
  name: string,
  description: string
): Promise<{ msg: string; directives: Directive[] }> {
  try {
    const res = await api.put(`/api/directives/add`, { id, name, description })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addDirectives(id, name, description))
  }
}

export async function deleteDirectives(id: string): Promise<{
  msg: string
  directives: Directive[]
}> {
  try {
    const res = await api.delete(`/api/directives/delete/${id}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteDirectives(id))
  }
}

export async function addDirectiveAction(
  action: string,
  target: string,
  type: string,
  value: string,
  id: string
): Promise<{
  msg: string
  directives: Directive[]
}> {
  try {
    const res = await api.post(`/api/directives/actions`, { action, target, id, type, value })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addDirectiveAction(action, target, id, type, value))
  }
}

export async function deleteDirectiveAction(
  id: string,
  directiveId: string
): Promise<{
  msg: string
  directives: Directive[]
}> {
  try {
    const res = await api.delete(`/api/directives/actions`, { data: { id, directiveId } })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteDirectiveAction(id, directiveId))
  }
}

export async function editDirective(
  id: string,
  name: string,
  description: string,
  enabled: boolean,
  faviconDataUrl: string | null
): Promise<{ sg: string; directives: Directive[] }> {
  try {
    const res = await api.post(`/api/directives/edit`, { id, name, description, enabled, faviconDataUrl })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editDirective(id, name, description, enabled, faviconDataUrl))
  }
}

export async function deleteConditionId(
  id: string,
  directiveId: string
): Promise<{
  msg: string
  directives: Directive[]
}> {
  try {
    const res = await api.delete(`/api/directives/conditions`, { data: { id, directiveId } })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteConditionId(id, directiveId))
  }
}

export async function addCondition(
  type: string,
  entry: string,
  id: string
): Promise<{
  msg: string
  directives: Directive[]
}> {
  try {
    const res = await api.post(`/api/directives/conditions`, { type, entry, id })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addCondition(type, entry, id))
  }
}

export async function reclassifyBulkUrls(
  urls: SignatureUrlInfo[],
  signature_id: string,
  suggestion: string
): Promise<{
  msg: string
  success: boolean
  old_signatures: OldUrlInformation[]
}> {
  try {
    const res = await api.post('/api/urls/bulk/reclassify', { urls, signature_id, suggestion })
    return res.data.results
  } catch (err) {
    return handleApiError(err, () => reclassifyBulkUrls(urls, signature_id, suggestion))
  }
}

interface Results {
  entry_no: number
  org: string
  type: string
  title: string
  capture: string
  '1_percent': number
  '2_percent': number
  '3_percent': number
  '4_percent': number
  '5_percent': number
}

interface Entry {
  entry_no: number
  org: string
  title: string
  capture: string
}

export async function testMonitorModeration(entries: Entry[]): Promise<{
  msg: string
  results: Results[]
  version: string
}> {
  try {
    const res = await api.post('/api/keywords/monitor', { entries })
    return res.data.result
  } catch (err) {
    return handleApiError(err, () => testMonitorModeration(entries))
  }
}

export interface AppControls {
  id: string
  type: string
  appId: string
  signature_id: string
  modified: Date
}

export interface ControlledApps {
  id: string
  name: string
  favicon: string
}

export interface PlatformOption {
  id: string
  label: string
}

export interface AppListings {
  id: string
  name: string
  favicon: string | null | undefined
  category?: string
  format: string
}

export interface AppsSelectionProps {
  label: string
  value: string
  favicon: string | null | undefined
  format: string
}
export interface ControlledAppsOptionProps {
  favicon: string
  name: string
  value: string
  format: string
  [key: string]: any
}

export const platforms: PlatformOption[] = [
  { id: 'appstoreid', label: 'Apple App Store' },
  { id: 'playstoreid', label: 'Google Play Store' },
  { id: 'windows-filename', label: 'Windows - FileName' },
  { id: 'windows-publisher', label: 'Windows - Publisher/Company' },
  { id: 'windows-taskbar', label: 'Windows - Taskbar Name' },
  { id: 'osx-bundleID', label: 'OSX - BundleId' },
]

export async function getApps(): Promise<{ apps: AppControls[] }> {
  try {
    const res = await api.get('/api/applications/get')

    return res.data
  } catch (err) {
    return handleApiError(err, () => getApps())
  }
}

export async function getAppsCategories(): Promise<{ apps: ControlledApps[] }> {
  try {
    const res = await api.get('/api/applications/categories/get')
    return res.data
  } catch (err) {
    return handleApiError(err, () => getApps())
  }
}

export async function getControlledAppId(id: string): Promise<{ app: AppListings }> {
  try {
    const res = await api.get(`/api/applications/listing/${encodeURIComponent(id)}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getApps())
  }
}

export async function getAppListings(): Promise<{ apps: AppListings[] }> {
  try {
    const res = await api.get('/api/applications/listings')
    return res.data
  } catch (err) {
    return handleApiError(err, () => getApps())
  }
}

export async function putAppListing(
  controlId: string,
  controlName: string,
  favicon: string | null | undefined,
  category: string
): Promise<{ message: string }> {
  try {
    const res = await api.put('/api/applications/listings', { controlId, controlName, favicon, category })
    return res.data
  } catch (err) {
    return handleApiError(err, () => getApps())
  }
}

export async function deleteControlledApp(id: string): Promise<{ message: string }> {
  try {
    const res = await api.delete(`/api/applications/listing/${encodeURIComponent(id)}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteControlledApp(id))
  }
}

export async function editApplication(app: AddApp, oldApp: AppControls): Promise<{ message: string }> {
  try {
    const res = await api.post(`/api/applications/app`, { app, oldApp })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editApplication(app, oldApp))
  }
}

export async function editAppListing(
  id: string,
  name: string,
  favicon: string,
  category: string
): Promise<{ message: string }> {
  try {
    const res = await api.post(`/api/applications/listing`, { id, name, favicon, category })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editAppListing(id, name, favicon, category))
  }
}

export interface ProviderResponse {
  [key: string]: string | string[]
}

export async function queryProviders(url: string): Promise<{ results: ProviderResponse[]; msg: string }> {
  try {
    const res = await api.get(`/api/urls/queryproviders/${encodeURIComponent(url)}`)
    return res.data.results
  } catch (err) {
    return handleApiError(err, () => queryProviders(url))
  }
}

export interface ImageClassifierResponse {
  blip_caption: string
  clip_scores: { [key: string]: number }
  details: {
    CUDA: boolean
    clip_query: number
    screenshot: number
  }
  encoded_image: string
  url: string
}

export async function queryAppindexClip(url: string): Promise<{ data: ImageClassifierResponse; msg: string }> {
  try {
    const res = await api.get(`/api/research/query_image_classifiers`, {
      params: {
        url,
      },
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => queryAppindexClip(url))
  }
}

export async function queryAppindexClipImage(
  image: string,
  terms: string
): Promise<{ data: ImageClassifierResponse; msg: string }> {
  try {
    const res = await api.post(`/api/research/query_image_classifiers/image_upload`, {
      image,
      terms,
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => queryAppindexClipImage(image, terms))
  }
}

export async function ImageClassifierReloadTerms(): Promise<{ msg: string }> {
  try {
    const res = await api.get(`/api/research/reload`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => ImageClassifierReloadTerms())
  }
}

export interface ImageClassifierEntries {
  term: string
  category_id: string
  notes: string
  imported: Date
  email: string
}

export interface ImageClassifierCategory {
  id: string
  signature_id: string
  label: string
  threshold: number
}

export async function getImageClassifierEntries(): Promise<{
  msg: string
  entries: ImageClassifierEntries[]
}> {
  try {
    const res = await api.get(`/api/clip/entries`)
    return res.data.data
  } catch (err) {
    return handleApiError(err, () => getImageClassifierEntries())
  }
}

export async function addImageClassifierEntries(
  term: string,
  category_id: string,
  notes: string,
  email: string
): Promise<{
  msg: string
  entries: ImageClassifierEntries[]
}> {
  try {
    const res = await api.put(`/api/clip/entry`, { term, category_id, notes, email })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addImageClassifierEntries(term, category_id, notes, email))
  }
}

export async function deleteImageClassifierEntries(
  term: string,
  name: string
): Promise<{
  msg: string
  entries: ImageClassifierEntries[]
}> {
  try {
    const res = await api.delete(`/api/clip/entry/${name}`, { data: { term } })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteImageClassifierEntries(term, name))
  }
}

export async function editImageClassifierEntries(
  term: string,
  category_id: string,
  notes: string,
  email: string,
  oldTerm: string,
  oldNotes: string
): Promise<{
  msg: string
  entries: ImageClassifierEntries[]
}> {
  try {
    const res = await api.post(`/api/clip/entry`, { term, category_id, notes, email, oldTerm, oldNotes })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editImageClassifierEntries(term, category_id, notes, email, oldTerm, oldNotes))
  }
}

// Categories
export async function getImageClassifierCategory(): Promise<{
  msg: string
  entries: ImageClassifierCategory[]
}> {
  try {
    const res = await api.get(`/api/clip`)
    return res.data.data
  } catch (err) {
    return handleApiError(err, () => getImageClassifierEntries())
  }
}

export async function addImageClassifierCategory(
  label: string,
  signature_id: string,
  threshold: number
): Promise<{
  msg: string
  entries: ImageClassifierCategory[]
}> {
  try {
    const res = await api.put(`/api/clip`, { label, signature_id, threshold })
    await api.put(`/api/mappings`, {
      signatureId: signature_id,
      providerId: 'clip',
      providerCategory: res.data.data[1]['id'],
      providerDescription: label,
      ttl: 7776000,
      ranking: '500',
    })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addImageClassifierCategory(signature_id, label, threshold))
  }
}

export async function deleteImageClassifierCategory(
  id: string,
  signature_id: string
): Promise<{
  msg: string
  entries: ImageClassifierCategory[]
}> {
  try {
    const res = await api.delete(`/api/clip/${id}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteImageClassifierCategory(id, signature_id))
  }
}

export async function editImageClassifierCategory(
  id: string,
  name: string,
  threshold: number,
  oldSignature: string,
  signature_id: string
): Promise<{
  msg: string
  entries: ImageClassifierCategory[]
}> {
  try {
    const res = await api.post(`/api/clip`, { id, name, threshold })
    if (oldSignature !== signature_id) {
      await api.delete(`/api/mappings`, {
        data: {
          signatureId: oldSignature,
          providerId: 'clip',
          providerCategory: id.toString(),
        },
      })
      await api.put(`/api/mappings`, {
        signatureId: signature_id,
        providerId: 'clip',
        providerCategory: id.toString(),
        providerDescription: name,
        ttl: 7776000,
        ranking: '500',
      })
    }

    return res.data
  } catch (err) {
    return handleApiError(err, () => editImageClassifierCategory(id, name, threshold, oldSignature, signature_id))
  }
}

export async function addURLSecondary(
  urls: SignatureUrlInfo[],
  signature_id: string
): Promise<{
  msg: string
  success: boolean
}> {
  try {
    const res = await api.post('/api/urls/secondary', { urls, signatureId: signature_id })
    return res.data.results
  } catch (err) {
    return handleApiError(err, () => addURLSecondary(urls, signature_id))
  }
}

export interface versions {
  versionId: number
  creationDate: Date
}

export async function getAvaliableVersions(): Promise<{ versions: versions[] }> {
  try {
    const res = await api.get(`/api/publish/versions`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getAvaliableVersions())
  }
}

export async function rollbackSignatures(version: number): Promise<{ msg: string }> {
  try {
    const res = await api.post(`/api/publish/rollback/${version}`)
    return res.data.msg
  } catch (err) {
    return handleApiError(err, () => rollbackSignatures(version))
  }
}

export async function getLastOutage(): Promise<{ outage: string }> {
  try {
    const res = await api.get(`/api/user/outage`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getLastOutage())
  }
}

export async function bulkClassify(urls: string[]): Promise<{ msg: string }> {
  try {
    const res = await api.post(`/api/urls/bulk/classify`, { urls })
    return res.data
  } catch (err) {
    return handleApiError(err, () => bulkClassify(urls))
  }
}
export interface GenericListEntry {
  list_id: string
  entry: string
  imported: Date
}
export interface GenericList {
  id: string
  name: string
  enabled: boolean
  entries?: GenericListEntry[]
}

export async function getGenericLists(): Promise<{ genericLists: GenericList[] }> {
  try {
    const res = await api.get(`/api/lists`)
    return res.data.data
  } catch (err) {
    return handleApiError(err, () => getGenericLists())
  }
}

export async function addGenericList(id: string, name: string, enabled: boolean): Promise<{ msg: string }> {
  try {
    const res = await api.put(`/api/lists`, { id, name, enabled })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addGenericList(id, name, enabled))
  }
}

export async function deleteGenericList(list: string): Promise<{ msg: string }> {
  try {
    const res = await api.delete(`/api/lists`, { data: { list } })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteGenericList(list))
  }
}

export async function editGenericList(id: string, name: string, enabled: boolean): Promise<{ msg: string }> {
  try {
    const res = await api.post(`/api/lists`, { id, name, enabled })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editGenericList(id, name, enabled))
  }
}

export async function getGenericListsEntries(): Promise<{ genericListsEntries: GenericListEntry[] }> {
  try {
    const res = await api.get(`/api/lists/entries`)
    return res.data.data
  } catch (err) {
    return handleApiError(err, () => getGenericListsEntries())
  }
}

export async function addGenericListEntries(list: string, entry: string): Promise<{ msg: string }> {
  try {
    const res = await api.put(`/api/lists/entries`, { list, entry })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addGenericListEntries(list, entry))
  }
}

export async function deleteGenericListEntries(list: string, entry: string): Promise<{ msg: string }> {
  try {
    const res = await api.delete(`/api/lists/entries`, { data: { list, entry } })
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteGenericListEntries(list, entry))
  }
}

export async function deleteMassListEntries(list: string): Promise<{ msg: string }> {
  try {
    const res = await api.delete(`/api/lists/entries/${list}`)
    return res.data.data
  } catch (err) {
    return handleApiError(err, () => deleteMassListEntries(list))
  }
}

export async function getBlockmanUrl(): Promise<{ msg: string; url: string }> {
  try {
    const res = await api.get(`/api/urls/blockman`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getBlockmanUrl())
  }
}

export async function promoteUrls(url: string, signature_id: string): Promise<{ msg: string }> {
  try {
    const res = await api.post(`/api/urls/promote`, { url, signature_id })
    return res.data
  } catch (err) {
    return handleApiError(err, () => promoteUrls(url, signature_id))
  }
}

export interface UserAccounts {
  email: string
  role: number
  last_login: Date
}

export async function getUserAccounts(): Promise<{ users: UserAccounts[] }> {
  try {
    const res = await api.get(`/api/user`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => getUserAccounts())
  }
}

export async function addUserAccount(email: string, role: number): Promise<{ msg: string }> {
  try {
    const res = await api.post(`/api/user`, { email, role })
    return res.data
  } catch (err) {
    return handleApiError(err, () => addUserAccount(email, role))
  }
}

export async function editUserAccount(email: string, role: number): Promise<{ msg: string }> {
  try {
    const res = await api.put(`/api/user`, { email, role })
    return res.data
  } catch (err) {
    return handleApiError(err, () => editUserAccount(email, role))
  }
}

export async function deleteUserAccount(email: string): Promise<{ msg: string }> {
  try {
    const res = await api.delete(`/api/user/${email}`)
    return res.data
  } catch (err) {
    return handleApiError(err, () => deleteUserAccount(email))
  }
}
