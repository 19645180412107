import {
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { UserAccounts } from '../util/Api'
import { useCallback, useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

interface UserTableProps {
  loading: boolean
  shownUsers: UserAccounts[]
  onEdit: (user: UserAccounts) => void
  onDelete: (user: UserAccounts) => void
}

const UserTable = ({ loading, shownUsers, onEdit, onDelete }: UserTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [orderBy, setOrderBy] = useState<keyof UserAccounts>('role')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  const getUsers = useCallback(() => {
    const sortedUrls = shownUsers.sort((a, b) => {
      if ((a[orderBy] || '') > (b[orderBy] || '')) return order === 'asc' ? 1 : -1
      if ((a[orderBy] || '') < (b[orderBy] || '')) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentUrls: (UserAccounts | null)[] = sortedUrls.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentUrls.length
    if (currentUrls.length === rowsPerPage) return currentUrls
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentUrls.push(null)
    }
    return currentUrls
  }, [shownUsers, page, rowsPerPage, order, orderBy])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const createSortHandler = (key: keyof UserAccounts) => (e: React.MouseEvent) => {
    if (key !== orderBy) {
      setOrder('asc')
      return setOrderBy(key)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  const handleClickEdit = (e: React.MouseEvent<HTMLButtonElement>, user: UserAccounts | null) => {
    e.preventDefault()
    if (!user) return
    onEdit(user)
  }

  const handleOpenDelete = (e: React.MouseEvent<HTMLButtonElement>, user: UserAccounts | null) => {
    e.preventDefault()
    if (!user) return
    onDelete(user)
  }

  useEffect(() => {
    setPage(0)
  }, [shownUsers])

  return (
    <Paper variant="outlined">
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'email'}
                  direction={orderBy === 'email' ? order : 'asc'}
                  onClick={createSortHandler('email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'role'}
                  direction={orderBy === 'role' ? order : 'asc'}
                  onClick={createSortHandler('role')}
                >
                  Permission
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'last_login'}
                  direction={orderBy === 'last_login' ? order : 'asc'}
                  onClick={createSortHandler('last_login')}
                >
                  Last Login
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Edit/Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getUsers().map((row, index) => (
              <TableRow
                hover
                key={`user-table-item-${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer', height: 40 }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.email || ''}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {(() => {
                      switch (row?.role) {
                        case 0:
                          return 'Admin'
                        case 3:
                          return 'Blockman Read-Only'
                        case 5:
                          return 'Read-Only'
                        default:
                          return ''
                      }
                    })()}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap sx={{ maxWidth: 240 }}>
                    {row?.last_login
                      ? Intl.DateTimeFormat().resolvedOptions().timeZone.substring(0, 7) === 'America'
                        ? Intl.DateTimeFormat('en-US', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          }).format(new Date(row?.last_login))
                        : Intl.DateTimeFormat('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          }).format(new Date(row?.last_login))
                      : ''}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {row?.email && (
                    <>
                      <IconButton
                        size="small"
                        disabled={loading || !row?.email}
                        onClick={(e) => handleClickEdit(e, row)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton size="small" onClick={(e) => handleOpenDelete(e, row)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={shownUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default UserTable
